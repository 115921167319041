<template>
  <div id="country-comparison" class="title-container w-100 z-100 fixed-top text-white">
    <h1 class="title fs-3 m-0 py-3 text-center fw-bold">Geographical Zone Comparison</h1>
  </div>
  <div id="menus-dropdown-comparator" class="center-element d-flex justify-content-center">
    <div class="wrapper d-flex justify-content-center align-items-center w-100 gap-3 mb-3 flex-wrap">
      <div class="dropdown-1">
        <IndicatorDropDown 
          :data="data" 
          :title="'Indicator'" 
          :queryTitle="'Select Indicator'" 
          :titleV="'false'" 
          :push="false"
          @getIndicatorFromDropDown="handleDataFromChildID"
        />
      </div>
      <span class="material-icons">compare_arrows</span>
      <div class="dropdown" v-if="regions.length > 0 && country.length > 0">
        <MenuDropDown
          ref="menuDropDownRef"
          :data="regions"
          :dataorg="regionsorg"
          :data_country="country"
          :title="'Region'" 
          :queryTitle="'Select Region'" 
          :path="path" 
          :push="false"
          @getElementFromDropDown="handleDataFromChildRE"
          @custom-event-to-dropdown="handleCustomEventToDropdown"
        />
      </div>
      <div class="button-container">
        <button class="export-button text-white border border-0 py-2 px-4 ms-2" @click="triggerComparison">Compare</button>
      </div>
    </div>
  </div>

  <div class="center-verticaly d-flex flex-column align-items-center justify-content-center" v-if="has_error">
      <AlertMessage @ERROR="error" :message="errorMessage" :type="'warning'"/>
  </div>
  <div class="center-verticaly d-flex flex-column align-items-center justify-content-center"  v-if="regions.length > 0 && Object.keys(countriesData).length > 0">
    <ComparisonBar :regions="regions" :countriesData="countriesData"/>
  </div>
</template>

<script>
/* eslint-disable */
import IndicatorName from './app.json'
import ComparisonBar from '../components/CountryComparison/ComparisonBar.vue'
import MenuDropDown from '../components/UI/MenuDropDownMul'
import IndicatorDropDown from '../components/UI/MenuDropDownIndicator'
import AlertMessage from '../components/UI/AlertMessage.vue'
import api from '@/services/api'

export default {
  components: {
    'MenuDropDown': MenuDropDown,
    ComparisonBar,
    IndicatorDropDown,
    AlertMessage
  },
  data() {
    return {
      regions: [],
      regionsorg: [],
      country: [],
      path: 'country-comparison',
      countriesData : {},
      data: IndicatorName,
      indicatorSave: '',
      regionSave: '',
      receivedDataId: this.$route.params.indicator,
      receivedDataRe: this.$route.params.region,
      has_error: false,
      errorMessage: 'Please select an Indicator and a Region',
    }
  },
  methods: {
    triggerComparison() {
      // Call the methods that handle data from children
      this.$refs.menuDropDownRef.notifyChild();

      // Check if both receivedDataId and receivedDataRe are defined
      if (this.receivedDataId !== undefined && this.receivedDataRe !== undefined) {
        // Update the route and reload the page
        this.$router.push(`/${this.path}/${this.receivedDataRe}/${this.receivedDataId}`).then(() => {
          window.location.reload();
        });
      }
    },
    handleDataFromChildID(data) {
      this.receivedDataId = data;
      this.$refs.menuDropDownRef.notifyChild();
      if (this.receivedDataId !== undefined && this.receivedDataRe !== undefined) {
        this.$router.push(`/${this.path}/${this.receivedDataRe}/${this.receivedDataId}`).then(() => {
        window.location.reload()
        })
      }
    },
    handleDataFromChildRE(data) {
      this.receivedDataRe = data;
      if (this.receivedDataId !== undefined && this.receivedDataRe !== undefined) {
        this.$router.push(`/${this.path}/${this.receivedDataRe}/${this.receivedDataId}`).then(() => {
        window.location.reload()
        })
      }
    },
    error(data) {
      this.has_error = data
    },
    compare() {
      if (this.receivedDataId == '' || this.receivedDataRe == '') {
        this.has_error = true;
        console.log(this.receivedDataId)
        console.log(this.receivedDataRe)
      } else { 
        console.log(this.receivedDataId)
        console.log(this.receivedDataRe)
          this.$router.push(`/${this.path}/${this.receivedDataRe}/${this.receivedDataId}`).then(() => {
          window.location.reload()
        });
      }
    },
    async getRegions() {
      try {
        const response = await api.get('/regions');
        const data = await response.data;
        this.regions = data.data.map(item => [item.name]).filter(item => item[0] !== 'World');;
        console.log('THIS.REGION : ',this.regions)
      } catch (error) {
        console.error('Error retrieving regions:', error);
      }
    },
    async getRegionsOrg() {
      try {
        const response = await api.get('/regions?isorg=1');
        const data = await response.data;
        this.regionsorg = data.data.map(item => [item.name]);
        console.log('THIS.REGIONORG : ',this.regionsorg)
      } catch (error) {
        console.error('Error retrieving regions:', error);
      }
    },
    async getAllCountries() {
      try {
        const response = await api.get('/countries?cnttype=REG');
        const data = await response.data;
        this.country = data.data.map(item => [item.country_name]);
        console.log("this.country",this.country)
      } catch (error) {
        console.error('Erreur lors de la récupération des pays :', error);
      }
    },
    async getCountries() {
        try {
            const response = await api.get('/countries');
            const data = await response.data;

            this.countriesData  = data
            console.log("this.countriesData",this.countriesData)
        } catch (error) {
            console.error('Error retrieving countries:', error);
        }
    },
  },
  mounted() {
    this.getRegions();
    this.getRegionsOrg();
    this.getCountries()
    this.getAllCountries()
  }
}
</script>