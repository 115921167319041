<template>
        <div class="table-container me-0 me-md-3">
          <div class="table-wrapper overflow-auto">
            <table id="my-table" class="elem-content-table content-table w-100">
            <thead>
              <tr class="text-white text-left fw-b">
                <th @click="sortBy('rank')">rank <i class="fas fa-sort fs-5 opacity-50 ms-1"></i></th>
                <th @click="sortBy('country_id')">iso3 <i class="fas fa-sort fs-5 opacity-50 ms-1"></i></th>
                <th @click="sortBy('country_name')">country <i class="fas fa-sort fs-5 opacity-50 ms-1"></i></th>
                <th @click="sortBy('myvalue')">value <i class="fas fa-sort fs-5 opacity-50 ms-1"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(country, index) in sortedData" :key="index">
                <td>{{ country.rank }}</td>
                <td>{{ country.country_id }}</td>
                <td>{{ country.country_name }}</td>
                <td>{{ country.myvalue }}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
        <div class="button-container d-flex justify-content-end mt-1">
        <button class="export-button text-white border border-0 py-2 px-4 me-3" @click="exportToPDF">Export to PDF</button>
        <button class="export-button text-white border border-0 py-2 px-4 me-3" @click="exportToCSV">Export to CSV</button>
        </div>
        <!-- Tableau dupliqué pour que le pdf prenne bien en compte le tableau en totalité (ne pas toucher ou alors l'export PDF ne marchera)-->
        <div class="table-container2 flex-column" style="width: 500px;">
          <div id="my-table2" class="table-wrapper overflow-auto">
            <h1 class="d-flex justify-content-center text-center m-3">{{ PdfTitle }}</h1>
            <table class="elem-content-table content-table2 w-100">
            <thead>
              <tr class="text-white text-left fw-b">
                <th class="px-2 py-3 fs-5">rank</th> 
                <th class="px-2 py-3 fs-5">country</th>
                <th class="px-2 py-3 fs-5">value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(country) in data" :key="country">
                <td class="px-2 py-3 fs-5" >{{ country.rank }}</td>
                <td class="px-2 py-3 fs-5" >{{ country.country_name }}</td>
                <td class="px-2 py-3 fs-5" >{{ country.myvalue }}</td>
              </tr>
            </tbody>
          </table>
          </div>
          <div class="button-container d-flex justify-content-end mt-1">
            <button class="export-button text-white border border-0 py-2 px-4" @click="exportToPDF">Export to PDF</button>
            <button class="export-button text-white border border-0 py-2 px-4" @click="exportToCSV">Export to CSV</button>
          </div>
        </div>
  </template>
  
  <script>
  /* eslint-disable */
  import html2pdf from 'html2pdf.js';
  import api from '@/services/api'
  
  export default {
    components: {
      Map
    },
    props: {
      PdfTitle: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        whichTab: false,
        countries: [],
        SelectedInd: '',
        data: [],
        sortByColumn: '',
        sortDirection: 'asc'
      }
    },
    computed: {
      sortedData() {
        const dataCopy = [...this.data];
        if (this.sortByColumn) {
          dataCopy.sort((a, b) => {
            const aValue = a[this.sortByColumn];
            const bValue = b[this.sortByColumn];

            if (aValue === 'N/A') {
              return 1; // Move 'N/A' to the end
            }
            if (bValue === 'N/A') {
              return -1; // Move 'N/A' to the end
            }

            const modifier = this.sortDirection === 'asc' ? 1 : -1;
            return aValue > bValue ? modifier : -modifier;
          });
        }
        return dataCopy;
      }
    },
    methods: {
      exportToCSV() {
        const data = this.data
        const getHeaders = data => Object.keys(data[0]).filter(header => header !== 'timestamp');
        console.log(getHeaders)
        const getRows = data => data.map(item => Object.values(item).filter((value, index) => Object.keys(item)[index] !== 'timestamp'));
        const headers = getHeaders(data);
        const rows = getRows(data);
        rows.unshift(headers);
        const csvData = rows.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.$route.params.element + '_tab.csv';
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
      },
      sortBy(column) {
        if (this.sortByColumn === column) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortByColumn = column;
          this.sortDirection = 'asc';
        }
      },
      exportToJSON() {
        const filteredData = this.data.map(item => {
            const { timestamp, ...rest } = item;
            return rest;
        });

        const jsonData = JSON.stringify(filteredData);
        const blob = new Blob([jsonData], { type: 'application/json' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'countryRanking.json';
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
      },
      exportToPDF() {
        const element = document.getElementById('my-table2');
        html2pdf()
          .from(element)
          .save(this.$route.params.element + '_tab.pdf');
      },
      selectedIndicator()  {
        if (this.$route.params.element) {
          const indicator = this.$route.params.element.split('?')
          this.selectedInd = indicator[0];
        }
      },
      changeTab(targetValue) {
        if (targetValue !== this.whichTab) {
          this.whichTab = targetValue;
        }
      },
      async getIndicator() {
          try {
            const reponse = await api.get(`data/ssind/${this.selectedInd}?orderby=rank&start_date=2024-01-01&end_date=2024-01-01`)
            const data = await reponse.data
            this.data = data.data

            this.data.forEach(item => {
              if (item.myvalue)
                item.myvalue = Math.round(item.myvalue * 100) / 100;
              else {
                item.myvalue = 'N/A'
              }
              if (!item.rank) {
                item.rank = 'N/A'
              }
            })
            countriesWithValues.sort((a, b) => a.rank - b.rank);
            this.countries = countriesWithValues.concat(countriesWithoutValues);
          } catch (error) {
            console.error('Erreur lors de la récupération des données : ', error)
          }
        },
      async getCountries() {
            try {
                const response = await api.get('/countries');
                const data = await response.data;
                data.data.forEach(obj => {
                  this.countries.push({
                    name: obj.country_name,
                    value: 'N/A',
                    rank: 'N/A',
                  });
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des pays :', error);
            }
        },
    },
    async mounted() {
        this.selectedIndicator();
        await this.getCountries();
        await this.getIndicator();
    }
  }
  </script>