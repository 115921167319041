<template>
    <label v-if="hasError">
      <div class="alert" :class=type>
        <span class="alertClose" @click="clearError">X</span>
        <span class="alertText">{{ message }}</span>
      </div>
    </label>
  </template>
  
  <script>
  export default {
    props: {
      error: {
        type: Boolean,
        required: true,
      },
      message: {
        type: String,
        required: false,
      },
      type: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        hasError: true,
      };
    },
    watch: {
      error(newValue) {
        this.hasErrorLocal = newValue;
      },
    },
    methods: {
      clearError() {
        this.hasError = false;
        this.$emit('ERROR', this.hasError);
      },
    },
    beforeUnmount() {
        this.hasError = true;
    }
  };
  </script>