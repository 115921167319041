<template>
  <div class="select text-white d-flex justify-content-between align-items-center p-3 gap-2" @click="open_menu">
    <span class="selected">{{ selectedElem }}</span>
      <div class="caret" :class="openMenu ? 'caret-rotate' : ''"></div>
  </div>
  <ul class="menu2 list-unstyled mt-1" :class="openMenu ? 'menu-open opacity-100 d-block' : ''" style="width: 300px !important;">
    <li class="searchBox">
      <input
        v-model="searchTerm"
        :placeholder="queryTitle"
        @click.stop
        class="border border-0 text-white"
      />
    </li>
    <li 
      v-for="(item, index) in filteredCountries"
      :key="index"
      :class="selectedElem == item[0] ? 'active' : ''"
      @click="new_selected(item[0])"
      > {{ item[0] }}  </li>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value) && value.length > 0;
      },
      coerce(value) {
        return value.trim();
      },
    },
    title: {
      type: String,
      required: false,
    },
    queryTitle: {
      type: String,
      required: false,
    },
    path: {
      type: String,
      required: true,
    },
    push : {
      type: Boolean,
      required: true,
    },
    margin : {
      type: Boolean,
      required: false,
    }
  },
  data () {
        return {
            openMenu: false,
            selectedElem: this.title,
            searchTerm: '',
            filteredCountries: this.data,
        }
    },
    methods: {
        open_menu() {
            this.openMenu = !this.openMenu
        },
        new_selected(element) {
            this.open_menu()
            if (this.push) {
              this.$router.push(`/${this.path}/${element}`).then(() => {
                window.location.reload()
              });
            } else {
              this.$emit('getElementFromDropDown', element);
            }
            localStorage.setItem('selectedElem', element);
            this.selectedElem = element
        },
        filterCountries() {
          if (this.searchTerm.trim() === '') {
            this.filteredCountries = this.data;
          } else {
            this.filteredCountries = this.data.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
    },
    watch: {
      searchTerm() {
        this.filterCountries();
      },
    },
    mounted() {
      const savedselectedElem = localStorage.getItem('selectedElem');
      if (savedselectedElem && this.$route.params.region) {
        this.selectedElem = savedselectedElem;
      } else {
        this.selectedElem = this.title
      }
  }
}
</script>