import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import CountryComparison from '../views/CountryComparison.vue'
import CountryProfile from '../views/CountryProfile.vue'
import IndicatorViz from '../views/IndicatorViz.vue'
import MethodoPage from '../views/MethodoPage.vue'
import FindOut from '../views/FindOut.vue'
import ReputationRisk from '../views/ReputationRisk.vue'

// SSO
import store from '../store';
import AuthLogin from '../views/AuthLogin.vue'
import AuthCallback from '../views/AuthCallback.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {requiresAuth: true},

  },
  {
    path: '/login',
    name: 'login',
    component: AuthLogin,
  },
  {
    path: '/auth/callback',
    component: AuthCallback,
  },
  {
    path: '/country-comparison',
    name: 'country-comparison',
    component: CountryComparison,
    meta: {requiresAuth: true},
  },
  {
    path: '/country-comparison/:region/:indicator',
    name: 'country-comparison-element',
    component: CountryComparison,
  },
  {
    path: '/indicator',
    name: 'indicator-visualization',
    component: IndicatorViz,
  },
  {
    path: '/indicator/:element',
    name: 'indicator-visualization-element',
    component: IndicatorViz,
  },
  {
    path: '/methodology',
    name: 'methodology-insight',
    component: MethodoPage,
  },
  {
    path: '/findoutmore',
    name: 'find-more',
    component: FindOut,
  },
  {
    path: '/reputationrisk',
    name: 'reputation-risk',
    component: ReputationRisk,
  },
  {
    path: '/reputationrisk/:country',
    name: 'reputation-risk-country',
    component: ReputationRisk,
  },
  {
    path: '/country',
    name: 'country-profile',
    component: CountryProfile,
  },
  {
    path: '/country/:element',
    name: 'country-profile-element',
    component: CountryProfile,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// guard SSO
router.beforeEach((to, from, next) => {
  if (!store.state.isLoggedIn) {
    if (to.path !== '/login') {
      next('/login');
    } else {
      next();
    }
  } else if (to.path === '/login') {
    next('/');
  } else {
    next();
  }
  // next();
});

export default router
