<template>
    <section id="methodo-page">

        <h1 class="text-danger fw-bold text-center px-5 mt-3">Find out more...</h1>
        <p>
            If you're looking for more detailed information on a particular subject, the following links 
            may be useful.
        </p>
        <h2 class="fw-bold px-5 mt-2">Traveler safety</h2>
        <p>
            France Diplomatie - Conseils aux Voyageurs : Regional maps of vigilance zones, up-to-the-minute 
            information and alerts.<br>
            <a href="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs" target="_blank"><u>www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs</u></a>
        </p>
        <h2 class="fw-bold px-5 mt-2">Climate Risk</h2>
        <p>
            Climate Watch : Climate Watch offers open data, visualizations and analysis to help policymakers, 
            researchers and other stakeholders gather insights on countries' climate progress.<br>
            <a href="https://www.climatewatchdata.org" target="_blank"><u>www.climatewatchdata.org</u></a>
        </p>
        <p>
            IPCC (GIEC) : The Intergovernmental Panel on Climate Change (IPCC) is the United Nations body for 
            assessing the science related to climate change.<br>
            <a href="https://www.ipcc.ch" target="_blank"><u>www.ipcc.ch</u></a>
        </p>
    </section>
</template>