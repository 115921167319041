<template>
  <div class="chart-container1 w-100 rounded-2 m-0 overflow-hidden" id="chartdivBar"></div>
</template>

<script>
/* eslint-disable */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { scaleLinear } from "d3-scale";
export default {
  data() {
    return {
      data: [
        {
          category: "Economic & Business Risk",
          id: "pillar1",
          value: Math.round(this.data_country.pillar1 * 100)/100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(3)"
          }
        },
        {
          category: "Political & Geopolitical Risk",
          id: "pillar2",
          value: Math.round(this.data_country.pillar2 * 100)/100,
          full: 5,
          columnSettings: {
            fill: "linear-gradient(to right, #00FF00, #FFFF00, #FFA500, #FF0000)"
          }
        },
        {
          category: "Social & Societal Risk",
          id: "pillar3",
          value: Math.round(this.data_country.pillar3 * 100)/100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(1)"
          }
        },
        {
          category: "Environmental Risk",
          id: "pillar4",
          value: Math.round(this.data_country.pillar4 * 100)/100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(2)"
          }
        }
      ],
      selectedCountry: null
    };
  },
  props: {
    data_country: {
      type: Object,
      required: true,
    }
  },
  methods: {
    getColor(value) {
      const ranges = [1, 2.001, 3, 3.5, 4, 5];
      const colors = ['#029602', '#78be21ff', '#f1c232', '#eb4b52', '#cc0000'];

      let category = '';
      if (value >= 5) {
        category = colors[4];
      } else {
        for (let i = 1; i < ranges.length; i++) {
          if (value >= ranges[i - 1] && value < ranges[i]) {
            category = colors[i - 1];
            break;
          }
        }
      }

      return category;
    },
    GetCountry() {
      this.countryIn = this.$route.params.element;
    },
    updateData() {
      if (!this.countryIn)
        return
      const countryData = this.data_country;
      this.data.forEach((category, index) => {
        const key = category.id;
        const value = parseFloat(countryData[key]);
        
        this.data.value = value;

        const colorScale = scaleLinear()
          .domain([0, 2, 3.5, 4.5, 5])
          .range(['#00FF00', '#FFD300', '#FFA500', '#FF0000']);

        this.data[index] = {
          ...this.data[index],
          columnSettings: {
            fill: am4core.color(this.getColor(value)),
          }
        };
      });
      this.chart.data = this.data;
    },
    createChart() {
      am4core.useTheme(am4themes_animated);

      this.chart = am4core.create("chartdivBar", am4charts.XYChart);
      this.chart.padding(40, 40, 40, 40);
      this.chart.background.fill = am4core.color("#ffffff");
      
      let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.grid.template.disabled = true;

      var subtitle = this.chart.titles.create();
      subtitle.text = "From 1 (low risk) to 5 (very high risk)";
      subtitle.fontSize = 15;
      subtitle.marginBottom = 15;
      
      var title = this.chart.titles.create();
      title.text = "Risk Assessment by pillar";
      title.fontSize = 25;
      title.marginBottom = 2;
      
      let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 5;
      valueAxis.strictMinMax = true;
      valueAxis.renderer.minGridDistance = 50;
      valueAxis.renderer.extraMax = 0;

      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "category";
      series.dataFields.valueX = "value";
      series.tooltipText = "{valueX.value}";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusBottomRight = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.horizontalCenter = "left";
      labelBullet.label.dx = 10;
      labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.00as')}";
      labelBullet.locationX = 1;

      series.columns.template.adapter.add("fill", function(fill, target) {
        return target.dataItem.dataContext.columnSettings.fill;
      });

      this.chart.exporting.menu = new am4core.ExportMenu();
      this.chart.exporting.menu.align = "left";
      this.chart.exporting.filePrefix = this.$route.params.element + "RiskAssessmentByPillar";
      this.chart.exporting.menu.verticalAlign = "top";
	    this.chart.exporting.menu.items = [{
		  "label": "...",
		  "menu": [
				  {
				"label": "Image",
				"menu": [
				  { "type": "png", "label": "PNG" },
				  { "type": "jpg", "label": "JPG" },
				  { "type": "svg", "label": "SVG" },
				  { "type": "pdf", "label": "PDF" }
				]
			  },
			{ "label": "Print", "type": "print" }
		  ]
		}];
      this.chart.data = this.data;
      this.updateData()
    }
  },
  mounted() {
    this.GetCountry();
    this.createChart();
    this.updateData();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
