<template>
    <div class="d-flex justify-content-evenly text-center">
      <div class="parent">
      <div class="child1 ps-2">
        <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA89JREFUaEPtmUnIjlEUx3/fSpmipAgZyoIyhMxFVopiIYVEskEyZdpgg5VhgUKGQpKQothQZMqYSFHGbJQxYad/nUfX9Qz3Ps/zvvrqO/X2vd/bueee/z3nnum20MqppZXrTxuA/23BOi3QF5gEDAX0PfkI4yvn8wi4av9Xxl8VwCBgsSk+LFKbhwbkAPA0cu0f9rIAOgOrgJWAvlehr8BOYAeg71FUBsAcYCMwOGqnf5l/Ae2cn58AW4ETMXJjAawHtsVskMM72aw43ePZAGwP3SMGwF1gRKjgAD5ZUL6/zID0c9bcA0YGyAjOAy8tqoTIDOF5DwwAfhpzb2CtgUnWK3K5oFLlhljgMLAgRKsIni3A5hT+3cBy5/cjwMI8uUUApLgA1EkKAHn36CQw29lQAAQk2gIKjzdKRhslKp2ySz+A58DHgtPoBJy33CJWRadxWSE2zwIy8aaSRy8AijJlaSBwBehpArJcLvMSK8PerJCkqgKQ3u4BKsGNTcvYWRZQVlSWLUt1AOgBKHQnVlC2Vvb/i7IA3AJGl9XeapwqLpRs7VrhNjAmBEAv4G0F5bV0KbC3ogwt962gfPHOlZtmgbnAMWNSBMirefxIo2VJlfm5BgAScQqYZbLmAceLAMjXVhiTYvCaHBCZ0aEm5SVGddE6k7fLv5tpFjgLzLAFSuXt7RTSLNEMAOo39ps+54CZRRZ4ACTNSQJQYVWm9EE0A8AE4JopLfccXgTgE9DFmFwLpYFoBoBuwAfTR/eqawwAAfniLMiyRIzLq8oU8Mz6xhMmF/4eA8B1oSHAY09gHSC+Ad2dcjrvABQ638S4kHuJpwEXUqTXAUKJThm7iOTz92MusRtGlwD7MnaoCiIUgKLOmZgw6iaygzY2yTqlKiBCAewBdJCioETmlhKaHOjWq5avG0QogDvAKNs8qJQQr1vMrbaZTZ6vaiKn+j2GQgCooJQuouBiTsxuOX0dmFigWaMAqCaTS4uiymm/oVkEHMoBUQaAWwhetvbV3WIqcNF+iG5otM6txTUGmQI8ywDRB3gd4z8pvC+smVfI7Ahcsl5YrNEtpRb5Tf1pp6xN01WuNr4iiMTPjwLzTVbppl7r/bFKUe1TxwBMe7jDhNJjleQw/cFWEQjV7/0LrBVqqMqDrWQj/2SLQJS51D6o2kaLiWB/uKsmQx8NYn2qCqD24W6iYNp4PQ1IFQANG68nILIeOJTyFUX0V5OD2MzclAeOBETRE5MakA6BN7XpT0yuXq32kS/tcFvlM2uglzSOreiBo3E71yS5DUBNB1lazG8XqdUxSy3o4AAAAABJRU5ErkJggg==" 
          @click="changeChartField('risk')"
          :class="{ 'border-b' : currentChartField == 'risk'}"
        >
        <br>
        <span class="fs-7">Global Country Risk</span>
      </div>
      <div v-if="showTooltipOCR" class="child1 ps-2 child2"><p>Country Risk (Consolidated)</p></div>
    </div>
      <div class="parent">
        <div class="child1 ps-2">
          <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAq9JREFUaEPtmT1oFEEUx3/XicFKbEwQBCFNEDUGURttUqhYCH6hkKigWAgKloLaCYLpRMUgFiooKPhJiJAEgoJEiIVgEwhaKAja2Cv/Y+dYlrjzJtmb3Qv7moPbNzP/35vZN29mG3S4NTpcPzVA2TNYz0AnzcAJ4BzQC6wsSfhH4CVwxY1vXUJjwGBJohca9glwSA8sAKeB2xUS76QcAx5aAG4CZysI8AbYYwGYAHZ5ADYDswVDfgN6PH02igB4D+woWLy6mwZ2xgDYC7xuA8AR4FG7ASRcABZbBfQDM8AfS4MkXV7O8W0tIa3ztE2lcm32HXgOfAX0/1ODkG3ACLA95fsOuA/cMbTfChwFuoHDGf8WwN/Mg6s5ALuBScPAcjkJjOb4NjOJsS+5ZXW2FcAn3uk+ADwzQkQD6AK+GNKgdM8Bm4zvRTSAAeCDMapyU7rUe+GzaACngLs+Nann2ulvGfyjAQwD9wyCnMsZY0aKBrAR+BQAoFSpUtln0QAkRGs6nfv/J+4FsN+nPHkeFUAHH2Uin60FvvucygDQmOsBbVaCydoPYJ9x6bi2hczAT0Clrk5F1wyR055wCVgHKNrzScSvA78N7Q8CF5NSQuVE2ha1E6c7GG/zUVNnX1MxF1ILZYOmkiEkZRqC3nQ5nxSBef5LngF1/hnos6oK8FNa3eLxNwG8MlSMqlCLtsfAmpxOfwGr3ZEybwndAC4Ura6A/h4Axy0ASnXabKpmKhhnLAAS7ssGseFaBy4rgASqXhkCNgArYitOxlN99Ta5Xmz+FQJQkub8YZc1gDKTAN1v6Awstl12nNZN9EIC3Awo56rmqJrp9iN3j3EAVb2BNgMo8lX7BiBNQQBqoK8wKqJ0JKyCBQNUQXSQBsv1elCHsZ1rgNgRz45Xz0A9A0uMwD/2BqoxpWNOAwAAAABJRU5ErkJggg=="
          @click="changeChartField('pillar1')"
          :class="{ 'border-b' : currentChartField == 'pillar1'}"
          >
          <br>
          <span class="fs-7">Economic & Business Risk</span>
        </div>
      <div v-if="showTooltipECO" class="child1 ps-2 child2"><p>Economic & Business Risk</p></div>
    </div>
    <div class="parent">
      <div class="child1 ps-2">
        <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAtJJREFUaEPt2TeoFUEUxvH/K2zFWmsbbSy10+phqWAOmBMmDJgQA2JCeZhQFEUxKwoqgoWgWAiChYXYWAgWFhaChYVgIR/MfVwvu7Nn0mUvuO2bnXN+eya9uUMM+DM04PnTD8AU4A/wscTHKg2YBtxwgIXAh9yIkoAZwE1ggkv6MyDE+5yIUoCZwB1gXE+yX4AFwLtciBKAWcB9YExNkl8d4m0ORAnAHOBBQ3LfHOJNKqIEQDlZEN+B+cCrFEQpgBXxA5gHvIxF5ABMcl/8UEUSlkr8dJV4EYNIBSh5jffJwAHgcCTil6vE81BECqA7+U7c/cCRSMRvYC7wNAQRC6hKvhN3H3A0EqEjh+bEYysiFqD+fYi9wLFIhF5TJR5aECmAJsRu4EQEwpy8+g4BzK4pra8Su4CTAYig5EMAw4BWCI1NBel9fIidwCkDIjh5K2A68AQY65LQ2AxF7ABOexBRyVsAU4FHwPie4DGIbcBIDcI0YasmtW8O6D8pbVITa1aDGMRW4IxldbG28QEOut3V11cMYgtw1ppgU7umVUi7qjam3IhNwPmm5Cx/bwKoj+OAlsPciI3ABUuSvjYWgN7XMri9AGIDcDEFYQUohlYQTcLclVgPXIpFhAAU4xyg0udGrAUuxyBCAYqhkq8rgFgDXAlFxAAUQ19rdQHEKuBqCCIWoBgKtMITzHc88J2dVgLXrIgUgGLo2nBpRTDL2caHWA5ctyBSAYpxC1jUFcySfKe5D7HMfSCvIwdAAe66m4WQ5C0IVVf3q7VPLoAC6Aol9lTpq8QSV+VKRE6AZcj62vgQi4HbVS+3CaD8fAhdzWuo/vO0DdCE0NX8vW5BGwFNCN0bjd5+txXgQ+iHk9Flu82AKoQOk5utQ0i3Ef14XjcE6UzsZ8CekEmsHx76gbCMAiE+hS6jbQJE7cQDD+jH+E+OYRl/yUFKdvAfUPLrWvr+CxBzkjH5BMpaAAAAAElFTkSuQmCC" 
          @click="changeChartField('pillar2')"
          :class="{ 'border-b' : currentChartField == 'pillar2'}"
        >
      <br>
      <span class="fs-7">Political & Geopolitical Risk</span>
      </div>
      <div v-if="showTooltipPOL" class="child1 ps-2 child2"><p>Political & Geopolitical Risk</p></div>
    </div>
    <div class="parent">
      <div class="child1 ps-2">
        <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAu5JREFUaEPtl0vIjkEUx3/fmixcFoQVEZGycItQEiFZSSELtwUpl42FT0kRCQuyclmxocglQsqtiNw2KCGSy0Ks6a95aprmmWfme+bL99acenp73mfOOf//OTPnnOmiw6Wrw/FTCPzvDJYMlAy0jEDZQi0D2Fq9ZKAmhP2AccBE8/0Z8Ar43TrkjoHeyMBKYA8w0vH1BTgDbM9JIjeBbmBXBMBsfrMZAmYCdyLAa8ka4GTk2uCyXAT6A4+AMZa3u8B1874cGGt9ewdMAH61JZGLwHRAgCu5CCxxwP1x3mcA9/oKgQ3AMQvMeuCEA849HxuB451EYAtwyALcpwjEbKEnwCSLQK9uoQHAfGA88BK4BvwMpLvuEN8wOm5pfWvIhA5xFAbfIR4FPAXUTStRB1X03gRIpJTRZcD5gK1oDD4Cl4EFHuNXgIUNhy6mkV2tsW+bjsbgEhDz1wGQoxuyINW6UeIjcBg40BCEJAwugSnAg4CDqcDDiNLXZphLwuASGAh8DwAcBPyIINBmSRIG3xnYBBzxINgMHA0gWwTMBoYBQ61fqXwGPlm/t4FLAVvRGOpGif3AXGAy8Bi4CezwOJwGLAZWG8ApkRehU4DGjvsexSgMbWahmIoTS2g3IHvJ4iMwD9BeH2weGXWNN4FX3/hqHukPMY/dW1ywPhKV32+AHp3PasL9p18R0PVvBbDOOLKNrzI3qeq/OvDq1roPXDDXR1805WcpMMt0+iYSKsmnnUUKjAbFs8BzEZDRc2Zs8Dl1s6Qyq1JnyzbgYGL+t3p6gkq0SrUt7hhefXsPrBW4FwHwaj4jLGu+JrMT2JsIvlquwrDP0XWb5QdgeJ19EahjKB1VB02alfjK25xE8K4/lVRb3HKtS4+qnVeaCMi4DbDp8CZy8S53D/Mt018KAUWgT2Yg1EC0X5XSSnrUbHqwr2w/QZ9tOnEPcOVXKQTyxzTNYslAWrzyry4ZyB/TNIslA2nxyr+6ZCB/TNMs/gV7DJzyzsJ2swAAAABJRU5ErkJggg==" 
          @click="changeChartField('pillar3')"
          :class="{ 'border-b' : currentChartField == 'pillar3'}"
        >
      <br>
      <span class="fs-7">Social & Societal Risk</span>
      </div>
      <div v-if="showTooltipSOS" class="child1 ps-2 child2"><p>Social & Societal Risk</p></div>
    </div>
    <div class="parent">
      <div class="child1 ps-2">
        <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABO9JREFUaEPtmWeoHkUUhp+IqGCwYX5pLFiwYGLD3kEsINgxJIq9YUXFAqL+EuxgL6BgAztKiIpgQ1FUsGMviAW7ILY/ygOzYTKZ2Z39du8VwQNLcvebOXPemVPeMzuDqZe/gQuAS6diqRlToTTRKQDlHOCKsdebTgDafiZwzZggphuAtp8CXD8WiLEBrAPMAdYEZgP+fVjG2BOAW8YAMQYADdwV2A3YqIdRRwO39xifHToEgAafB+w1wIgFwN0D5jMJgGVDSjx7yMLR3EOB+yfV1RfAasAdwH6TLpjMG2S8uvoAWBd4FRDEGDLY+D4AtgVeqrT6d+CT6Dk9M28U42sBrAV8DOj7bfIk8FB4vosGNpW4eTWa8TUAlgOeAzyBkuhWJwOvFAbEAEY1vgaABMxUWZKngD07TqYBUGv8IX2yUlsQbwm8CCxfMPAS4OKKuBBAH+PX6MOX2gDcCVhocnIzcGKF8Q7J7egsYNMw3w1aO7ip1flw4K5K3cU0anV9vKDE9/vULpCM2xe4ENiuZf7ewBO1+ksnoGtcVFCyTUvAtq27EBBAl2wNvNY1qPm9BODpQM5SPaZKT2cr4Cvg68qFDgQezIz9Hlg9eS+D/bxSb9aFVgJ+KSjQ7/V/FxGk/zaF63ngWuDdzFznHJ+8fzaw2HT4isBvQwDog4syCv4ALGpNkTK9pn3ur8B14fky0vEhsH709w0hCSyTrPMZIGWplpwLWZRyHdN7wMaRZrPLfYWVXgd2B34Ov38aTusv4AzgWMA0nYqZ74hq6wtkzsb7rIySNPvYD+hGJbkSaCi3aXGnULGNh2MKk3o3ObkTkM8ckFlA1zg1ep8DoKtsEMa8HKXL+YFPbQ9cVTDensCC10tyAB4G9s9oMUBPi96bieRBqThGF7E3bvRLwXcEHi1Y904wvkkAJQqzFHXJAbg6+Gm6loEd5/FVgR8zBn0bUq2XWfGO6oJS8oOjKmxMmPMFHGcvg/ykjG5TsfMXSw6AQSaIVD6K3KP5zSA2mFN5A9gjAjgPuAeYC7wZMtIq4f+CSKWk9zbguC4Auo9ulJMcYHfKHWvkBeABYL0oZt4KnZxErUZKhfQy4NwuAN7pfFFYxRRnqktFHzd4fwA8qQ2B90NKtNIauLUpUipR6i0OCg3T4vVLVEIXMAhT6ZMprOYWPQGsHAqXFblLbgK8+ErFim/c/dl1Av7ujnmPmcpPwBaVXMWgje+MNgPe7rBeWu3uS7dT8TbkqPRl6QTaquytGV6T6tX/5fUxo5WClyh6M9/rxiWCNFJsLTERLCFtDU26g/FEd8IdiWWT0ABZBEtXjB+EeV4pfpPMP7LlqtH+QI62lLQB6KIK8bWgxpv6mi6rw1NIC5e729aFyaue6QvA8ZdHfCY3375Yw/sY3+hpQFjsSs2TY+VmfhzJStfN3ExAnr95y5ZqSO3Op2q65spqdwak6RMBcNIOgMXp3xD5kzcjRek6gWaindiN04zACm9NaJVaACrpCrSutfr8Xv3doA8ADdgl8KSxbqhTULJb07DXmVXSF4BKZZTy9UnvhkqGSdfPB6Qx1TIJgEa5FNkvjgb5EDFI7fbunUTJEADNelIGv9j4rFBphDccj4Unx24r1fT7QtOlVOPtJXQxm5XmcZ63E82jizwCCGKwjHECg40YouB/AEN2b4y5//kT+Ac0j+cxq5d8PAAAAABJRU5ErkJggg==" 
          @click="changeChartField('pillar4')"
          :class="{ 'border-b' : currentChartField == 'pillar4'}"
        >
      <br>
      <span class="fs-7">Environmental Risk</span>
      </div>
      <div v-if="showTooltipENV" class="child1 ps-2 child2"><p>Environmental Risk</p></div>
    </div>
    </div>
    <div class="hello-country-map w-100" id="chartdivMap">
    </div>
  </template>
  
  <script>
  /* eslint-disable */
import { countryToAlpha3 } from "country-to-iso";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
export default {
  data() {
    return {
      selectedCountry: {
        id: '',
      },
      currentChartField: "risk",
      showTooltipBUR: false,
      showTooltipENV: false,
      showTooltipSOS: false,
      showTooltipPOL: false,
      showTooltipECO: false,
      showTooltipOCR: false,
    }
  },
  props: {
      all_data_country: {
        type: Object,
        required: true,
      }
    },
  methods: {
    getColor(value) {
      /*const ranges = [1, 1.5, 2, 2.5, 3, 3.5, 4, 5];
      const colors = ['#005c00', '#029602', '#3be33b', '#ffd700', '#f59218', '#ff0000', '#cc0000'];*/
      const ranges = [1, 2.001, 3, 3.5, 4, 5];
      const colors = ['#029602', '#78be21ff', '#f1c232', '#eb4b52', '#cc0000'];

      let category = '';
      if (value >= 5) {
        category = colors[4];
      } else {
        category = '#808080'
        for (let i = 1; i < ranges.length; i++) {
          if (value >= ranges[i - 1] && value < ranges[i]) {
            category = colors[i - 1];
            break;
          }
        }
      }
      return category;
    },
    changeChartField(field) {
      this.currentChartField = field;
      this.renderChart();
    },
    GetCountry() {
      this.countryIn = this.$route.params.element;
      if (!this.countryIn)
        return
      if (Object.prototype.hasOwnProperty.call(this.all_data_country, countryToAlpha3(this.countryIn))) {
        this.selectedCountry.id = this.all_data_country[countryToAlpha3(this.countryIn)].name;
      }
    },
    /*handleHit(ev) {
      this.$router.push('/country/' + ev.target.dataItem.dataContext.name).then(() => {
        window.location.reload()
      })
    },*/
    handleHit(ev) {
      let cntname = ev.target.dataItem.dataContext.name;
      if (cntname == "Palestinian Territories"){
        cntname = "Palestine"
      }
      const url = '/country/' + cntname;
      // console.log(ev.target.dataItem.dataContext)
      window.location.href = url;
    },
    renderChart() {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdivMap", am4maps.MapChart);

      chart.geodata = am4geodata_worldLow;

      chart.projection = new am4maps.projections.Miller();

      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      polygonSeries.useGeodata = true;

      const mapData = Object.values(this.all_data_country).map(item => ({
        id: item.name,
        value: item[this.currentChartField],
        value_str: item[this.currentChartField] !== null ? Math.round(item[this.currentChartField] * 100) / 100 : '',
        fill: am4core.color(this.getColor(item[this.currentChartField])),
      }));
      polygonSeries.data = mapData;

      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.applyOnClones = true;
      polygonTemplate.togglable = true;
      polygonTemplate.tooltipText = "{name} : {value_str}";
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeOpacity = 0.5;
      polygonTemplate.fill = 'rgb(128, 128, 128)';
      polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      polygonTemplate.events.on("hit", this.handleHit);
      polygonTemplate.fill = 'rgb(128, 128, 128)';
    
      polygonTemplate.propertyFields.fill = "fill";

      polygonSeries.exclude = ["AQ"];

      chart.smallMap = new am4maps.SmallMap();

      chart.smallMap.align = "right";
      chart.smallMap.valign = "top";
      chart.smallMap.series.push(polygonSeries);

      chart.zoomControl = new am4maps.ZoomControl();

      let homeButton = new am4core.Button();
      homeButton.events.on("hit", function(){
        chart.goHome();
      });
      if (this.selectedCountry.id.length > 0) {
        chart.events.on("ready", (ev) => {
          let country = polygonSeries.getPolygonById(this.selectedCountry.id);

          chart.zoomToMapObject(country);

          setTimeout(function() {
            country.isActive = true;
          }, 1000);
        });
      }
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "left";
      chart.exporting.filePrefix = this.$route.params.element + this.currentChartField + "Map";
      chart.exporting.menu.verticalAlign = "top";
      homeButton.icon = new am4core.Sprite();
      homeButton.padding(7, 5, 7, 5);
      homeButton.width = 30;
      homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
      homeButton.marginBottom = 10;
      homeButton.parent = chart.zoomControl;
      homeButton.insertBefore(chart.zoomControl.plusButton);
    }
  },
  mounted() {
    this.GetCountry()
    this.renderChart()
  }
}
  
  </script>