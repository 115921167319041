<template>
  <aside
	class="d-flex flex-column"
    id="sidebar"
    :class="{ 'is-expanded': is_expanded }"
    @mouseover="is_expanded = true"
    @mouseout="is_expanded = false"
  >
    <div class="logo">
		<img src="@/assets/veolia_logo.png" alt="Vue" style="width: 2rem;"/>
    </div>

    <h3>Menu</h3>
    <div class="menu">
      <router-link to="/methodology" class="button d-flex align-items-center">
        <span class="material-icons">extension</span>
        <span class="text">Methodology</span>
      </router-link>
      <router-link to="/" class="button d-flex align-items-center">
        <span class="material-icons">home</span>
        <span class="text">Home</span>
      </router-link>
	<router-link to="/country/France" class="button d-flex align-items-center">
		<span class="material-icons">public</span>
		<span class="text">Country Risk Profile</span>
	</router-link>
      <router-link to="/indicator/overallcountryrisk" class="button d-flex align-items-center">
        <span class="material-icons">display_settings</span>
        <span class="text">Indicator Browser</span>
      </router-link>
		<router-link to="/country-comparison/Western%20Europe/overallcountryrisk" class="button d-flex align-items-center">
        <span class="material-icons">compare_arrows</span>
        <span class="text">Geographical Zone Comparison</span>
      </router-link>
	<router-link to="/reputationrisk" class="button d-flex align-items-center" @click="reloadPage('/reputationrisk')">
        <span class="material-icons">workspace_premium</span>
        <span class="text">Reputation Risk</span>
      </router-link>
    </div>

    <div class="flex"></div>

    <div class="menu">
      <div @click="logout" class="button d-flex align-items-center" style="cursor: pointer">
        <span class="material-icons">logout</span>
        <span class="text">Logout</span>
      </div>
    </div>
  </aside>
</template>

<script>
import store from "@/store";
import { googleLogout } from "vue3-google-login"

/* eslint-disable */
export default {
	data() {
		return {
			is_expanded: false,
		}
	},
	methods: {
	  reloadPage(route) {
      window.location.href = route;
    },
    logout() {
      googleLogout()
      store.commit("setIsLoggedIn", false);
      this.$router.push({ name: "login" });
    }
	}
}
</script>