<template>
    <div class="loading_screenshot">
        <div class="capture_loading w-100 h-100 d-flex align-items-center justify-content-center">
        <p class="screen_progress">Screenshot in progress</p>
        </div>
    </div>
    <div class="content-container">
        <div  v-if="selectedC" class="title-container fixed-top w-100 z-100 text-white">
            <h1 class="title d-flex align-items-center justify-content-center">
                <strong>{{ selectedC }}</strong>
                <img v-if="selectedC" :src="drapeauUrl" alt="Drapeau du pays" class="drapeau" style="width: 50px; height: 50px;">
            </h1>
        </div>
        <div  v-else class="title-container fixed-top w-100 z-100 text-white">
            <h1 class="title fs-3 m-0 ps-5 py-2 text-center fw-bold">
                <strong>World Reputation Risk Map</strong>
            </h1>
        </div>
        <div class="text-center mt-4 fs-4">
            <p class="intro text-center">
                Reputation risk refers to negative signals in the country, <br> 
                based on external data, which could impact the public perception and image of a foreign company operating in it,<br> 
                <b>regarding environmental, social & societal and political & geopolitical stakes.</b><br>
                <i style="font-size: 14px;">(based on a subset of the same indicators used for the Global Country Risk)</i>
            </p>
            </div>
        <div class="d-flex align-items-center justify-content-between mx-4 my-2" v-if="country.length > 0">
            <div class="dropdown-content" v-if="country.length > 0">
                <MenuDropDown :data="country" :title="title" :queryTitle="queryTitle" :path="path" :push="true"/>
            </div>
            <div class="button-container mt-0 me-2">
                <button @click="exportToPDF" class="download_countryprofile d-flex align-items-center justify-content-center border border-0 text-white" :class="{ hovered: isHovered }">
                    <span>Export to PDF</span>
                </button>
            </div>
        </div>
        <div class="text-center mx-auto my-4">
            <span v-if="selectedC" class="subtitle fs-4" :style="getStatusClass(this.data_country.reputationalRisk)">
                {{ status }}
            </span>
        </div>
        <div v-if="!selectedC">
            <div class="progress-bar w-100 ms-2" v-if="Object.keys(all_data_country).length > 0">
                <MapWorld :all_data_country="all_data_country" :applyCustomClass="false"/>
            </div>
        </div>
        <div v-else>
            <div v-if="this.iscritical === 1" class="embargo_text text-center my-2">
                The Reputation Risk of this country has been penalized because of the embargo measures.
            </div>
        <!-- Your template for the case when selectedC is not empty -->
        <div class="wrapper d-flex justify-content-center justify-content-md-between align-items-end mb-4 mx-4 col flex-wrap ">
            <div class="conteneur-graphscore col-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2" v-if="Object.keys(data_country).length > 0">
                <graphscore :data_country="data_country"/>
            </div>
            <div class="conteneur-graphcnt col-12 col-md-9 col-lg-9 col-xl-10 col-xxl-5 mt-4 mt-md-0" v-if="Object.keys(data_country).length > 0">
                <div class="graph-title text-center fs-3">
                    Reputation Risk Assessment by Pillar
                </div>
                <graphCnt :data_country="data_country"/>
            </div>
            <div class="conteneur-mapwld col-12 col-xxl-5 mt-4 mt-xxl-0" v-if="Object.keys(all_data_country).length > 0">
                <div class="graph-title text-center fs-3 ">
                    Global Reputation Risk Assessment Map
                </div>
                <MapWorld :all_data_country="all_data_country" :applyCustomClass="true"/>
            </div>
        </div>
        <div class=" d-block w-100 px-4" v-if="Object.keys(data_indicator).length > 0 && this.$route.params.country">
            <Indicatorreput
                :selectedC="selectedC"
                :data="data"
                :data_indicator="data_indicator"
                :data_indicator_world="data_indicator_world"
                :data_indicator_region="data_indicator_region"
            />
        </div>
        <!-- <div class="text-indcrit">
            *List of critical indicators likely to significantly penalize Global Reputation Risk.
        </div> -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import MenuDropDown from '../components/UI/MenuDropDown'
import MapWorld from '../components/ReputationRisk/ReputationMap'
import graphCnt from '../components/ReputationRisk/CountrySolidGauge'
import graphscore from '../components/ReputationRisk/ReputGlobalRisk'
import Indicatorreput from '../components/ReputationRisk/CountryIndicatorreput'
import api from '@/services/api'
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
import IndicatorName from "./app_reput.json"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { hideElements } from '../services/utilities'; // Path to utilities.js
import { unhideElements } from '../services/utilities'; // Path to utilities.js


export default {
    components: {
      MapWorld,
      MenuDropDown,
      Indicatorreput,
      graphCnt,
      graphscore,
      props: ['applyCustomClass']
    },
    data () {
        return {
            data_country: {},
            all_data_country: {},
            country: [],
            data_indicator: [],
            data_indicator_world: [],
            data_indicator_region: [],
            regions_data: [],
            map: {},
            selectedC: this.$route.params.country || '',
            drapeauUrl: '',
            title: 'Select a country',
            queryTitle: 'Search Country',
            path: 'reputationrisk',
            data: IndicatorName,
            iscritical:'',
            status:null,
        }
    },
    methods: {
        getStatusClass(status) {
            if (status < 2.001) {
                return 'color: #029602';
            } else if (status >= 2  && status < 3) {
                return 'color: #78be21'
            } else if (status >= 3 && status < 3.5) {
                return 'color: #f1c232'
            } else if (status >= 3.5 && status < 4) {
                return 'color:  #eb4b52'
            } else {
                return 'color: #cc0000'
            }
        },
        async exportToPDF() {
            try {
                const loading = document.querySelector(".loading_screenshot");
                loading.classList.add('capture-mode_loading');
                const menuRows = document.querySelectorAll('.menu > ul > li');
                for (let i = 0; i < menuRows.length; i++) {
                    const checkbox = menuRows[i].querySelector('input[type="checkbox"]');
                    checkbox.click();
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                hideElements('.dropdown-container','.button-container');
                const container = document.querySelector('.content-container');
                const titleContainer = document.querySelector('.title-container');
                titleContainer.style.position = 'static';
                if (container) {
                    const canvas = await html2canvas(container);

                    const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, mm, A4 size

                    const image = canvas.toDataURL('image/png');
                    const imgWidth = 190; // A4 size: 210mm - adjusted for margins
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    const maxContentHeight = 250; // Maximum content height per page (adjust as needed)
                    const maxImageHeight = maxContentHeight; // Considering no content spacing

                    let imgWidthAdjusted = imgWidth;
                    let imgHeightAdjusted = imgHeight;

                    if (imgHeightAdjusted > maxImageHeight) {
                        const ratio = maxImageHeight / imgHeightAdjusted;
                        imgHeightAdjusted = maxImageHeight;
                        imgWidthAdjusted *= ratio;
                    }

                    const marginLeft = 10; // Left margin in mm
                    let marginTop = 10; // Top margin in mm

                    pdf.addImage(image, 'PNG', marginLeft, marginTop, imgWidthAdjusted, imgHeightAdjusted);
                    
                    const fileName = this.selectedC !== undefined ? this.selectedC : 'World';
                    pdf.save('reputationrisk_' + fileName + '.pdf');

                    // Revert the display property to its original state after capture
                    unhideElements('.dropdown-container','.button-container');
                    titleContainer.style.position = 'fixed';
                    const menuRows = document.querySelectorAll('.menu > ul > li');
                    for (let i = 0; i < menuRows.length; i++) {
                        const checkbox = menuRows[i].querySelector('input[type="checkbox"]');
                        checkbox.click();
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                    loading.classList.remove('capture-mode_loading');
                }
            } catch (error) {
                console.error('Error exporting to PNG:', error);
            }
        },
        modifyCountryToAlpha2(countryIdOrName) {
            const originalCountryToAlpha2 = countryToAlpha2;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'XK';
            } else {
                return originalCountryToAlpha2(countryIdOrName);
            }
        },
        modifyCountryToAlpha3(countryIdOrName) {
            const originalCountryToAlpha3 = countryToAlpha3;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo') | (countryIdOrName === 'XK')) {
                return 'KSV';
            } else {
                return originalCountryToAlpha3(countryIdOrName);
            }
        }, 
        async getAll() {
            try {
                let response;
                let pillar1risk = null;
                let pillar3risk = null;
                let pillar4risk = null;
                let reputationalRisk = null;

                if (this.selectedC !== '') {
                    response = await api.get(`/reputrisk/${this.modifyCountryToAlpha3(this.selectedC)}`);
                    this.iscritical = response.data.iscritical
                  } else {
                    response = await api.get(`/data/ssind/reputation_risk?start_date=2024`);
                }
                const data = response.data
                if (this.selectedC !== '') {
                    pillar1risk = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier2risk');
                    pillar3risk = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier3risk');
                    pillar4risk = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier4risk');
                    reputationalRisk = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'reputation_risk');
                }
                
                this.data_country = {
                    name: pillar1risk ? this.modifyCountryToAlpha3(pillar1risk.country_id) : null,
                    pillar1risk: pillar1risk ? pillar1risk.myvalue : null,
                    pillar3risk: pillar3risk ? pillar3risk.myvalue : null,
                    pillar4risk: pillar4risk ? pillar4risk.myvalue : null,
                    reputationalRisk: reputationalRisk ? reputationalRisk.myvalue : null
                };

                data.data.forEach(item => {
                    this.all_data_country[item.country_id] = {
                        reputationalRisk: null,
                        name: null,
                    };
                })
                data.data.forEach(item => {
                    this.all_data_country[item.country_id].name = this.modifyCountryToAlpha2(item.country_id)
                    if (item.symbol == 'reputation_risk') {
                        this.all_data_country[item.country_id].reputationalRisk = item.myvalue
                    }
                })
                const filteredData = data.data.filter(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC));
                filteredData.forEach(item => {
                    const newItem = {
                        name: item.country_id,
                        symbol: item.symbol,
                        myvalue: item.myvalue
                    };
                    this.data_indicator.push(newItem);
                });
                const filteredDataWorld = data.data.filter(item => item.country_id == 'WLD');
                filteredDataWorld.forEach(item => {
                    const newWorldItem = {
                        name: item.country_id,
                        symbol: item.symbol,
                        value: Math.round(item.myvalue * 100) / 100,
                    }
                    this.data_indicator_world.push(newWorldItem);
                })
                const filteredDataRegion = data.data.filter(item =>  item.country_id == 'EEU' || 
                                                                    item.country_id == 'CAM' ||
                                                                    item.country_id == 'CEA' ||
                                                                    item.country_id == 'CEAF'||
                                                                    item.country_id == 'EAA'||
                                                                    item.country_id == 'EAF'||
                                                                    item.country_id == 'EEU'||
                                                                    item.country_id == 'NAF'||
                                                                    item.country_id == 'NEU'||
                                                                    item.country_id == 'NME'||
                                                                    item.country_id == 'NOAM'||
                                                                    item.country_id == 'OCE'||
                                                                    item.country_id == 'SAF'||
                                                                    item.country_id == 'SAM'||
                                                                    item.country_id == 'SEA'||
                                                                    item.country_id == 'SEU'||
                                                                    item.country_id == 'SOA'||
                                                                    item.country_id == 'WAf'||
                                                                    item.country_id == 'WEU'
                                                                );
                filteredDataRegion.forEach(item => {

                    const newRegionItem = {
                        name: item.country_id,
                        symbol: item.symbol,
                        value: Math.round(item.myvalue * 100) / 100,
                    }
                    this.data_indicator_region.push(newRegionItem);
                })
                console.log(this.data_indicator_region)
                const ranges = [1, 2.001, 3, 3.5, 4, 5];
                const status = ["Low ", "Moderately Low", "Moderately High", "High", "Very High"];
                let category = 1;

                for (let i = 1; i < ranges.length; i++) {
                    if (this.data_country.reputationalRisk >= ranges[i - 1] && this.data_country.reputationalRisk < ranges[i]) {
                        category = i;
                        this.status = status[i - 1] +  " Reputation Risk";
                        break;
                    } else if (this.data_country.reputationalRisk >= 5) {
                        category = 5;
                        this.status = status[4] +  " Reputation Risk";
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        async fetchDrapeauUrl(pays) {
            try {
                // const response = await fetch(`https://restcountries.com/v3/alpha/${this.modifyCountryToAlpha2(pays)}`);
                // const data = await response.json();
                // this.drapeauUrl = data[0].flags[1];
                this.drapeauUrl = `https://flagsapi.com/${this.modifyCountryToAlpha2(pays)}/shiny/64.png`
            } catch (error) {
                console.error('Erreur lors de la récupération du drapeau :', error);
                this.drapeauUrl = 'URL_DU_DRAPEAU_PAR_DÉFAUT';
            }
        },
        async getRegions() {
        try {
            const response = await api.get('/regions');
            const data = response.data;
            data.data.forEach(obj => {
                    this.regions_data.push(obj.id)
            })
        } catch (error) {
            console.error('Erreur lors de la récupération des regions_data :', error);
        }
        },
        async getCountries() {
            try {
                const response = await api.get('/countries?cnttype=REG');
                const data = response.data;
                this.country = data.data.map(item => [item.country_name]);

            } catch (error) {
                console.error('Erreur lors de la récupération des pays :', error);
            }
        },
        async selectedCountry() {
            this.selectedC = this.$route.params.country;
            if (this.selectedC != undefined) {
                await this.fetchDrapeauUrl(this.selectedC);
            }
        },

    },
    mounted() {
        this.getRegions();
        this.getAll();
        this.getCountries();
        this.selectedCountry();
    },
}
</script>
