<template>
  <div class="select d-flex justify-content-between align-items-center text-white" @click="open_menu">
    <span class="selected">{{ 'Select a country and/or a region' }}</span>
    <div class="caret" :class="openMenu ? 'caret-rotate' : ''"></div>
  </div>
  <ul class="menu menu2 m-1" :class="openMenu ? 'menu-open opacity-100 d-block mb-0' : ''">
    <div class="container-mode d-flex w-100">
  <div class="mode-button d-flex justify-content-center" style="width: 33.33%;" @click="changeMode('regions')" :class="{ 'selectmode': Selectmode === 'regions'}">
    <label class="spacing-mode">Geographical Region</label>
  </div>
  <div class="mode-button d-flex justify-content-center" style="width: 33.33%;" @click="changeMode('regionsorg')" :class="{ 'selectmode': Selectmode === 'regionsorg'}">
    <label class="spacing-mode">Regional organization</label>
  </div>
  <div class="mode-button d-flex justify-content-center" style="width: 33.33%;" @click="changeMode('countries')" :class="{ 'selectmode': Selectmode === 'countries'}">
    <label class="spacing-mode">Country</label>
  </div>
</div>
    <li class="searchBox">
      <!-- Clear option to uncheck all checkboxes -->
      <button class="clear-button d-block text-center text-white border border-0 mx-auto my-0 mb-1 " @click="clearSelection">Clear</button>
      <input
        v-model="searchTerm"
        :placeholder="query"
        @click.stop
        class="m-O"
      />
    </li>
    <div>
      <li 
        v-for="(item, index) in filteredCountries"
        :key="index"
        :class="selectedElem == item[0] ? 'active' : ''"
        @click="new_selected(item[0])"
        class="d-flex justify-content-between align-items-center"
      > 
        <span class="flex-grow-1 text-truncate" style="width: 70%;">
          {{ item[0] }}
        </span>
        <input 
          type="checkbox" 
          :id="'checkbox_' + index" 
          :name="'checkbox_' + index" 
          :checked="isChecked(item[0])"
          @change="toggleChecked(item[0])"
          @click.stop
          style="width: 1.5rem; height: 1.5rem;" 
          class="m-0"
        >
      </li>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value) && value.length > 0;
      },
      coerce(value) {
        return value.trim();
      },
    },
    dataorg: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value) && value.length > 0;
      },
      coerce(value) {
        return value.trim();
      },
    },
    data_country: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value) && value.length > 0;
      },
      coerce(value) {
        return value.trim();
      },
    },
    title: {
      type: String,
      required: false,
    },
    queryTitle: {
      type: String,
      required: false,
    },
    path: {
      type: String,
      required: true,
    },
    push : {
      type: Boolean,
      required: true,
    },
    margin : {
      type: Boolean,
      required: false,
    }
  },
  data () {
        return {
            openMenu: false,
            selectedElem: this.title,
            searchTerm: '',
            dataMode: this.data,
            filteredCountries: this.data,
            checkedCountries: [],
            checkSignal: false,
            Selectmode: 'regions',
            query: 'select a region'
        }
    },
    methods: {
      clearSelection() {
      this.checkedCountries = [];
    },
        changeMode (mode) {
          this.Selectmode = mode;
        },
        notifyChild() {
          if (this.checkedCountries.length > 0) {
            this.$emit('getElementFromDropDown', this.checkedCountries);
          }
        },
        open_menu() {
            this.openMenu = !this.openMenu
        },
        isChecked(element) {
          return this.checkedCountries.includes(element);
        },
        toggleChecked(element) {
          if (this.isChecked(element)) {
            this.checkedCountries = this.checkedCountries.filter(
              (country) => country !== element
            );
          } else {
            this.checkedCountries.push(element);
          }
      
          console.log('Pays cochés :', this.checkedCountries);
        },
        new_selected(element) {
            this.open_menu()
            if (this.push) {
              this.$router.push(`/${this.path}/${element}`).then(() => {
                window.location.reload()
              });
            } else {
              this.$emit('getElementFromDropDown', element);
            }
            localStorage.setItem('selectedElem', element);
            this.selectedElem = element
        },
        filterCountries() {
          if (this.searchTerm.trim() === '') {
            this.filteredCountries = this.dataMode;
          } else {
            this.filteredCountries = this.dataMode.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
    },
    watch: {
      searchTerm() {
        this.filterCountries();
      },
      Selectmode(newMode) {
        if (newMode === 'regions') {
          this.dataMode = this.data;
          this.query = 'Select a region'
        } else if (newMode === 'regionsorg') {
          this.dataMode = this.dataorg;
          this.query = 'Select a region'
        } else {
          this.dataMode = this.data_country;
          this.query = 'Select a country'
        }
        this.filterCountries();
      },
    },
    mounted() {
      const savedselectedElem = localStorage.getItem('selectedElem');
      if (savedselectedElem && this.$route.params.region) {
        this.selectedElem = savedselectedElem;
      } else {
        this.selectedElem = this.title;
      }

      if (this.$route.params.region) {
        const selectedRegions = this.$route.params.region.split(',');

        // Check the corresponding checkboxes based on the URL parameters
        this.checkedCountries = selectedRegions;
        // Update the component state accordingly
        this.filterCountries();
      }
    }
}
</script>
