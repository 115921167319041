<template>
  <div>
    <p>Authenticating...</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted() {
    // Récupérez les paramètres de l'URL (votre serveur doit gérer la réponse SAML)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Envoyez les paramètres au serveur pour vérification et traitement
    // Utilisez une bibliothèque comme Axios pour effectuer la requête au backend
    // Le backend doit gérer la réponse SAML et créer la session utilisateur
    // Une fois le processus terminé, redirigez l'utilisateur vers la page d'accueil
    // Exemple d'utilisation d'Axios (vous devez adapter cela à votre backend)
    axios.post("/saml/callback", { samlResponse: urlParams.get("SAMLResponse") })
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.error(error);
        // Gérez les erreurs d'authentification ici
      });
  },
};
</script>