<template>
    <div class="hello hello-inidicator-map w-100" id="chartdivMap"></div>
</template>

<script>
/* eslint-disable */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
    data() {
        return {
            SelectedInd: '',
        }
    },
    props: {
        map : {
            type: Object,
            required: true,
        }
    },
    methods: {
        getColor(value) {
          const ranges = [1, 2.001, 3, 3.5, 4, 5];
          const colors = ['#029602', '#78be21', '#f1c232', '#eb4b52', '#cc0000'];
            if (value == 0 || value == null)
                return '#808080'
            let category = '';
            if (value >= 5) {
                category = colors[4];
            } else {
                for (let i = 1; i < ranges.length; i++) {
                if (value >= ranges[i - 1] && value < ranges[i]) {
                    category = colors[i - 1];
                    break;
                }
              }
            }
            return category;
        },  
        /*handleHit(ev) {
          this.$router.push('/country/' + ev.target.dataItem.dataContext.name);
        },*/
        handleHit(ev) {
          let cntname = ev.target.dataItem.dataContext.name;
          if (cntname == "Palestinian Territories"){
            cntname = "Palestine"
          }
          const url = '/country/' + cntname;
          // console.log(ev.target.dataItem.dataContext)
          window.location.href = url;
        },
        renderChart() {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("chartdivMap", am4maps.MapChart);

            chart.geodata = am4geodata_worldLow;

            chart.projection = new am4maps.projections.Miller();
            chart.homeGeoPoint = {
              latitude: 30, // Keep it centered vertically
              longitude: 50 // Shift to the left horizontally, adjust this value as needed
            };
            let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

            polygonSeries.useGeodata = true;

            const mapData = Object.values(this.map).map(item => ({
                id: item.name,
                value: item.risk,
                value_str: item.risk !== null ? Math.round(item.risk * 10) / 10 : '',
                fill: am4core.color(this.getColor(item.risk)),
            }));
            polygonSeries.data = mapData;


            let polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.applyOnClones = true;
            polygonTemplate.togglable = false;
            polygonTemplate.tooltipText = "{name} : {value_str}";
            polygonTemplate.nonScalingStroke = true;
            polygonTemplate.strokeOpacity = 0.5;
            polygonTemplate.fill = 'rgb(128, 128, 128)';
            polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            polygonTemplate.events.on("hit", this.handleHit);
            polygonTemplate.fill = 'rgb(128, 128, 128)';
            
            polygonTemplate.propertyFields.fill = "fill";

            polygonSeries.exclude = ["AQ"];
            var legend = new am4maps.Legend();
            legend.parent = chart.chartContainer;
            legend.background.fill = am4core.color("#000");
            legend.background.fillOpacity = 0.05;
            legend.width = 275;
            legend.align = "right";
            legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
            legend.data = [{
              "name": "Low (1 to 2)",
              "fill":"#029602"
            }, {
              "name": "Moderately Low (2.01 to 2.99)",
              "fill": "#78be21"
            }, {
              "name": "Moderately High (3 to 3.49)",
              "fill": "#f1c232"
            }, {
              "name": "High (3.5 to 3.99)",
              "fill": "#eb4b52"
            }, {
              "name": "Very High (4 to 5)",
              "fill": "#cc0000"
            },
            {
              "name": "no/partial assessment \ndue to lack of data",
              "fill": "#808080"
            }];
            legend.itemContainers.template.clickable = false;
            legend.itemContainers.template.focusable = false;

            var legendTitle = legend.createChild(am4core.Label);
            legendTitle.text = "Risk level :";
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.filePrefix = this.$route.params.element + "Map";
            chart.exporting.menu.align = "left";
            chart.exporting.menu.verticalAlign = "top";
            }
        },
        mounted() {
            this.renderChart()
    }
}
</script>