<template>
    <div :class="{ 'hello_reputation_map m-1 w-100': true, 'custom-class-1': applyCustomClass }" id="chartdivMap"></div>
</template>

<script>
/* eslint-disable */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { countryToAlpha2 } from "country-to-iso";
export default {
    props: { all_data_country: Object,
        applyCustomClass: {
            type: Boolean,
            default: true
        }},
    data() {
        return {
            selectedCountry: this.$route.params.country,
        }
    },
    methods: {
        getColor(value) {
            const ranges = [1, 2.001, 3, 3.5, 4, 5];
            const colors = ['#029602', '#78be21', '#f1c232', '#eb4b52', '#cc0000'];

            let category = '';
            if (value >= 5) {
                category = colors[4];
            } else {
                category = '#808080'
                for (let i = 1; i < ranges.length; i++) {
                if (value >= ranges[i - 1] && value < ranges[i]) {
                    category = colors[i - 1];
                    break;
                }
                }
            }
            return category;
        },
        modifyCountryToAlpha2(countryIdOrName) {
            const originalCountryToAlpha2 = countryToAlpha2;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'XK';
            } else {
                return originalCountryToAlpha2(countryIdOrName);
            }
        },
        renderChart() {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("chartdivMap", am4maps.MapChart);
            chart.geodata = am4geodata_worldLow;
            chart.projection = new am4maps.projections.Miller();
            if( !this.applyCustomClass){
                chart.homeGeoPoint = {
                    latitude: 30, // Keep it centered vertically
                    longitude: 50 // Shift to the left horizontally, adjust this value as needed
                };
            }
            let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
            polygonSeries.useGeodata = true;

            const mapData = Object.values(this.all_data_country).map(item => ({
                id: item.name,
                value: item.reputationalRisk,
                value_str: item.reputationalRisk !== null ? Math.round(item.reputationalRisk * 100) / 100 : '',
                fill: am4core.color(this.getColor(item.reputationalRisk)),
            }));
            polygonSeries.data = mapData;

            if( !this.applyCustomClass){
                chart.deltaLongitude = -10;
                var legend = new am4maps.Legend();
                legend.parent = chart.chartContainer;
                legend.background.fill = am4core.color("#000");
                legend.background.fillOpacity = 0.05;
                legend.width = 275;
                legend.align = "right";
                legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
                legend.data = [{
                    "name": "Low (1 to 2)",
                    "fill":"#029602"
                }, {
                    "name": "Moderately Low (2.01 to 2.99)",
                    "fill": "#78be21"
                }, {
                    "name": "Moderately High (3 to 3.49)",
                    "fill": "#f1c232"
                }, {
                    "name": "High (3.5 to 3.99)",
                    "fill": "#eb4b52"
                }, {
                    "name": "Very High (4 to 5)",
                    "fill": "#cc0000"
                },
                {
                    "name": "no/partial assessment \ndue to lack of data",
                    "fill": "#808080"
                }];
                legend.itemContainers.template.clickable = false;
                legend.itemContainers.template.focusable = false;

                var legendTitle = legend.createChild(am4core.Label);
                legendTitle.text = "Risk level :";
            }

            let polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.applyOnClones = true;
            polygonTemplate.togglable = true;
            polygonTemplate.tooltipText = "{name} : {value_str}";
            polygonTemplate.nonScalingStroke = true;
            polygonTemplate.strokeOpacity = 0.5;
            polygonTemplate.fill = 'rgb(128, 128, 128)';
            polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            polygonTemplate.events.on("hit", this.handleHit);
            polygonTemplate.propertyFields.fill = "fill";

            polygonSeries.exclude = ["AQ"];

            if( this.applyCustomClass){
                chart.smallMap = new am4maps.SmallMap();

                chart.smallMap.align = "right";
                chart.smallMap.valign = "top";
                chart.smallMap.series.push(polygonSeries);
            }

            chart.zoomControl = new am4maps.ZoomControl();

            let homeButton = new am4core.Button();
            homeButton.events.on("hit", function(){
                chart.goHome();
            });
            if (this.selectedCountry != null) {
                if (this.modifyCountryToAlpha2(this.selectedCountry).length > 0) {
                    chart.events.on("ready", (ev) => {
                    let country = polygonSeries.getPolygonById(this.modifyCountryToAlpha2(this.selectedCountry));

                    chart.zoomToMapObject(country);

                    setTimeout(function() {
                        country.isActive = true;
                    }, 1000);
                    });
                }
            }
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.align = "left";
            chart.exporting.menu.verticalAlign = "top";
            homeButton.icon = new am4core.Sprite();
            homeButton.padding(7, 5, 7, 5);
            homeButton.width = 30;
            homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
            homeButton.marginBottom = 10;
            homeButton.parent = chart.zoomControl;
            homeButton.insertBefore(chart.zoomControl.plusButton);
        },
        handleHit(ev) {
            let cntname = ev.target.dataItem.dataContext.name;
            if (cntname == "Palestinian Territories"){
                cntname = "Palestine"
            }
            const url = '/reputationrisk/' + cntname;
            // console.log(ev.target.dataItem.dataContext)
            window.location.href = url;
        },
    },
    mounted () {
        this.renderChart()
    }
}
</script>
