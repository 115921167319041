import axios from 'axios'

const ax = axios.create({
  // baseURL: 'http://127.0.0.1:5000',
   //baseURL: 'http://34.240.201.155:5000/',
   baseURL: 'https://veolia-api.taceconomics.com/',
   headers: { 'Content-Type': 'application/json', 'authorization' : 'Bearer sk_QJwkTtVZpEqYxK9UUEKh59yahPlETU4FL8YuvExLYDs' }
})

export const fetchData = async (url) => {
  try {
    const response = await ax.get(url);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error);
    throw error;
  }
}

export default ax