import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from "vue3-google-login";

import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'

createApp(App)
  .use(vue3GoogleLogin, { 
    clientId: "355919587778-frr792iqt9t2v6jsteg4pbtal9imb4o8.apps.googleusercontent.com" 
  })
  .use(router)
  .use(store)
  .mount('#app')
