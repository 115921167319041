<script setup>
import router from '@/router';
import store from '@/store';
const callback = () => {
  store.commit('setIsLoggedIn', true);
  router.push({ name: 'home' });
}
</script>

<template>
  <div class="login h-100">
    <div class="p-5 text-center bg-white rounded border">
      <img src="@/assets/veolia.png" width="250" alt="logo">
      <h1 class="mb-3 fw-bold">Country Risk Platform</h1>
      <h2>Access restricted to authorized users only.</h2>
      <GoogleLogin :callback="callback" auto-login>
        <button class="btn btn-primary">Login SSO</button>
      </GoogleLogin>
    </div>
  </div>
</template>

<style lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;

    h1 {
      font-size: 1.5rem;
      line-height: 0;
      padding-top: 15px;
    }

    h2 {
      font-size: 1rem;
      padding-bottom: 15px;
    }
  }
</style>