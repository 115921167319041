<template>
  <div class="app">
    <div class="sidebar" v-if="$route.path !== '/login'">
      <SideBar />
    </div>
    <div class="page" :style="($route.path === '/login' ? 'left: 0; width: 100%; padding-bottom: 0; height: 100%;' : '')">
      <RouterView />
      <Footer class="footer" v-if="$route.path !== '/login'" />
    </div>
  </div>
</template>

<script>
import { RouterView } from 'vue-router'
import SideBar from './components/UI/TheSidebar.vue'
import Footer from './components/UI/TheFooter.vue'

export default {
  name: 'app',
  components: {
    RouterView,
    SideBar,
    Footer
  },
}
</script>

<style lang="scss">
	@import '@/assets/app.scss';
</style>
