export function hideElements(...selectors) {
  selectors.forEach(selector => {
    const elementsToHide = document.querySelectorAll(selector);
    elementsToHide.forEach(element => {
      element.style.display = 'none';
    });
  });
}

export function unhideElements(...selectors) {
  selectors.forEach(selector => {
    const elementsToHide = document.querySelectorAll(selector);
    elementsToHide.forEach(element => {
      element.style.display = '';
    });
  });
}