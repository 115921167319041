<template>
  <div id="menu-dropdown-indicator" v-if="titleV == 'true'" class="title-container w-100 m-0 p-0 fixed-top text-white">
    <h1 class="title text-center fw-bold fs-3 p-3 mb-0">
        {{ selectedEle }}
    </h1>
  </div>
  <div class="dropdown col-12 col-lg-4" :class="{'custom-margin': margin}">
    <div class="select d-flex justify-content-between align-items-center text-white" @click="open_menu">
      <span class="selected me-2">{{ selectedEle }}</span>
        <div class="caret" :class="openMenu ? 'caret-rotate' : ''"></div>
    </div>
    <ul class="menu" :class="openMenu ? 'menu-open opacity-100 d-block mb-0' : ''">
      <ul class="first-label ps-0">
        <li v-for="(lvl1item, lvl1index) in menuItems" :key="lvl1index">
          <div class="searchInd d-flex justify-content-between align-items-center">
          <div class="label-container">
            <label class="first d-inline-block py-1" @click="new_selected(lvl1item.label, lvl1item.id)">{{ lvl1item.label }}</label>
          </div>
        <div>
        <label class="span-right" :for="lvl1item.id" v-if="lvl1item.id !== 'overallcountryrisk'"><span class="material-icons">arrow_drop_down</span></label>
        </div>
        </div>
          <input type="checkbox" :id="lvl1item.id">
          <ul class="lvl2menu p-0 pb-2 ms-2 " v-if="lvl1item.submenu && lvl1item.submenu.length > 0">
            <li v-for="(lvl2item, lvl2index) in lvl1item.submenu" :key="lvl2index">
              <div class="searchInd d-flex justify-content-between align-items-center">
            <div class="label-container">
              <label class="second p-1 d-inline-block" @click="new_selected(lvl2item.label, lvl2item.id)">{{ lvl2item.label }}</label>
            </div>
            <div class="p-1">
              <label class="span-right" :for="lvl2item.id"><span class="material-icons">arrow_drop_down</span></label>
            </div>
            </div>
              <input type="checkbox" :id="lvl2item.id">
              <ul class="lvl3menu p-0 pb-2 mx-2" v-if="lvl2item.submenu && lvl2item.submenu.length > 0">
                <li v-for="(lvl3item, lvl3index) in lvl2item.submenu" :key="lvl3index">
                  <div class="searchInd d-flex justify-content-between align-items-center">
                  <div class="label-container">
                  <label class="second p-1 d-inline-block" @click="new_selected(lvl3item.label, lvl3item.id)">{{ lvl3item.label }}</label>
                </div>
                <div class="p-1">
                  <label class="span-right" :for="lvl3item.id"><span class="material-icons">arrow_drop_down</span></label>
                </div>
                </div>
                  <input type="checkbox" :id="lvl3item.id">
                  <ul class="lvl4menu p-0 mx-2" v-if="lvl3item.submenu && lvl3item.submenu.length > 0">
                    <li v-for="(lvl4item, lvl4index) in lvl3item.submenu" :key="lvl4index">
                      <div class="searchInd d-flex justify-content-between align-items-center">
                      <div class="label-container">
                      <label class="second p-1 d-inline-block" @click="new_selected(lvl4item.label, lvl4item.id)">{{ lvl4item.label }}</label>
                    </div>
                    <div class="p-1">
                    </div>
                    </div>
                      <input type="checkbox" :id="lvl4item.id">
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </ul>
</div>
</template>

<script>
/* eslint-disable */
import api from '@/services/api'
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    queryTitle: {
      type: String,
      required: false,
    },
    path: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    titleV: {
      type: String,
      required: false,
    },
    push: {
      type: Boolean,
      required: false,
    },
    margin: {
      type: Boolean,
      required: false,
    }
  },
  data () {
        return {
            openMenu: false,
            selectedEle: '',
            searchTerm: '',
            filteredCountries: this.data,
            inputStrings: [],
            menuItems: [],
            RenameItem: {specificNames: {}},
          }
        },
        methods : {
        open_menu() {
            this.openMenu = !this.openMenu
        },
        new_selected(label, id) {
            this.open_menu()
            if (this.push) {
              this.$router.push(`/${this.path}/${id}`).then(() => {
                localStorage.setItem('selectedEle', label);
                window.location.reload()
              });
            } else {
              this.$emit('getIndicatorFromDropDown', id);
            }
            localStorage.setItem('selectedEle', label);
            this.selectedEle = label
        },
        filterCountries() {
          if (this.searchTerm.trim() === '') {
            this.filteredCountries = this.data;
          } else {
            this.filteredCountries = this.data.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
    buildRenameItem(obj, path) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const item = obj[key];
          const newPath = path ? `${path}/${key}` : key;
          if (item && item.code && item.code.indexOf('/') === -1) {
            this.RenameItem[item.code] = { label: item.description, value: item.value, rank : item.rank };
          } else {
            this.RenameItem.specificNames[item.code] = { label: item.description, value: item.value, rank : item.rank };
          }
          if (typeof item === 'object' && !Array.isArray(item)) {
            this.buildRenameItem(item, newPath);
          }
        }
      }
    },
    getDataFromCsv() {
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          const item = this.data[key];
          this.inputStrings.push(item.code);
        }
      }
      this.buildRenameItem(this.data, '');
    },
    async getSsindLab() {
      try {
          const response = await api.get('/ssind');
          const data = response.data;
          this.inputStrings.forEach(inputString => {
              this.generateMenu(inputString);
          });
          this.RenameInput(this.menuItems);
          for (const data of this.menuItems) {
            if (data.id == this.selectedEle) {
              this.selectedEle = data.label
            }

            if (Array.isArray(data.submenu)) {
              for (const submenuItem of data.submenu) {
                if (submenuItem.id == this.selectedEle) {
                  this.selectedEle = submenuItem.label
                  }
                  for (const data2 of submenuItem.submenu) {
                      if (data2.id == this.selectedEle) {
                        this.selectedEle = data2.label
                      }
                      for (const data3 of data2.submenu) {
                        if (data3.id == this.selectedEle) {
                          this.selectedEle = data3.label
                        }
                    }
                  }
                }
              }
          }
          this.emitIndicatorName()
      } catch (error) {
          console.error('Erreur lors de la récupération des Indicateurs :', error);
      }
    },
    generateMenu(inputString) {
      const levels = inputString.split('/');
      let currentLevel = this.menuItems;

      levels.forEach((level) => {
        let existingItem = currentLevel.find(item => item.label === level);

        if (!existingItem) {
          const menuItem = {
            id: level,
            label: level,
            submenu: [],
            value: 0,
            rank: 0,
          };
          currentLevel.push(menuItem);
          currentLevel = menuItem.submenu;
        } else {
          currentLevel = existingItem.submenu;
        }
      });
    },
    RenameInput(tab) {
      this.recursiveRename(tab)
  },
  recursiveRename(submenu, parentLabel = null) {
    submenu.forEach((item) => {
      const itemLabel = item.label;
      const combinedLabel = parentLabel ? parentLabel + "/" + itemLabel : itemLabel;

      if (this.RenameItem.specificNames[combinedLabel] && this.RenameItem.specificNames[combinedLabel].label) {
        item.label = this.RenameItem.specificNames[combinedLabel].label;
        item.value = this.RenameItem.specificNames[combinedLabel].value;
        item.rank = this.RenameItem.specificNames[combinedLabel].rank;
      } else if (parentLabel && this.RenameItem.specificNames[parentLabel] && this.RenameItem.specificNames[parentLabel].label) {
        item.label = this.RenameItem.specificNames[parentLabel].label;
        item.value = this.RenameItem.specificNames[parentLabel].value;
        item.rank = this.RenameItem.specificNames[parentLabel].rank;
      } else if (this.RenameItem[itemLabel] && this.RenameItem[itemLabel].label) {
        item.label = this.RenameItem[itemLabel].label;
        item.value = this.RenameItem[itemLabel].value;
        item.rank = this.RenameItem[itemLabel].rank;
      }

      if (item.submenu && item.submenu.length > 0) {
        this.recursiveRename(item.submenu, combinedLabel);
      }
    });
  },
  emitIndicatorName() {
        const data = this.selectedEle
        this.$emit("indicatorName", data)
    }
  },
    watch: {
      searchTerm() {
        this.filterCountries();
      },
    },
    mounted() {
      if (!this.$route.params.element && !this.$route.params.indicator) {
        this.selectedEle = this.title
      } else if (this.$route.params.indicator) {
        this.selectedEle = this.$route.params.indicator
      } else {
        this.selectedEle = this.$route.params.element
      }
      this.getSsindLab()
      this.getDataFromCsv()
  }
}
</script>

