<template>
    <div class="hello m-1" id="hello_reputation_global_risk" ref="chartdiv"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
/* eslint-disable */

export default {
  data() {
    return {
      selectedCountry: { id: '', value: '' },
      status: null
    }
  },
  props: {
    data_country: {
        type: Object,
        required: true,
    },
  },
  methods: {
    getStatusClass(status) {
      if (status < 2.001) {
        return 'color: #029602';
      } else if (status >= 2  && status < 3) {
        return 'color: #78be21'
      } else if (status >= 3 && status < 3.5) {
        return 'color: #f1c232'
      } else if (status >= 3.5 && status < 4) {
        return 'color:  #eb4b52'
      } else {
        return 'color: #cc0000'
      }
    },
    GetCountry() {
        this.selectedCountry.id = this.data_country.name;
        this.selectedCountry.value = this.data_country.reputationalRisk;
        this.selectedCountry.value_str = this.data_country.reputationalRisk !== null ? Math.round(this.data_country.reputationalRisk * 100) / 100 : '';
    },
    renderChart() {
      let root = am5.Root.new(this.$refs.chartdiv);
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        dx: 60,
        dy: -15,
        layout: root.verticalLayout,
      }));
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      let yAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yRenderer = yAxis.get("renderer");

      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("forceHidden", true);
      let data = [
      {
        category: "1",
        value: 100,
        risk: this.selectedCountry.value_str,
        currentBullet: false,
        columnSettings: {
          fill: am5.color(this.selectedCountry.value_str !== '' ? '#029602' : '#999999')
        }
      }, {
        category: "2",
        value: 100,
        risk: this.selectedCountry.value_str,
        currentBullet: false,
        columnSettings: {
          fill: am5.color(this.selectedCountry.value_str !== '' ? '#78be21' : '#999999')
        }
      }, {
        category: "3",
        value: 100,
        risk: this.selectedCountry.value_str,
        currentBullet: false,
        columnSettings: {
          fill: am5.color(this.selectedCountry.value_str !== '' ? '#f1c232' : '#999999')
        }
      }, {
        category: "4",
        value: 100,
        risk: this.selectedCountry.value_str,
        currentBullet: false,
        columnSettings: {
          fill: am5.color(this.selectedCountry.value_str !== '' ? '#eb4b52' : '#999999')
        }
      }, {
        category: "5",
        value: 100,
        risk: this.selectedCountry.value_str,
        currentBullet: false,
        columnSettings: {
          fill: am5.color(this.selectedCountry.value_str !== '' ? '#cc0000' : '#999999')
        } 
      }
    ];

    const ranges = [1, 2.001, 3, 3.5, 4, 5];
    const status = ["Low", "Moderately Low", "Moderately High", "High", "Very High"];
    let category = 1;

    for (let i = 1; i < ranges.length; i++) {
      if (this.selectedCountry.value >= ranges[i - 1] && this.selectedCountry.value < ranges[i]) {
        category = i;
        this.status = status[i - 1];
        break;
      } else if (this.selectedCountry.value >= 5) {
        category = 5;
        this.status = status[4];
      }
    }
    data.forEach(item => {
      item.currentBullet = item.category == category;
    });
    yAxis.data.setAll(data);

    let xAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 400,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(root, {})
    }));

    let xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "value",
      categoryYField: "category",
      riskYField: "risk",
      maskBullets: false,
      
    }));

      // chart.children.unshift(am5.Label.new(root, {
      //   text: "Risk Assessment",
      //   fontSize: 25,
      //   fontWeight: "500",
      //   textAlign: "center",
      //   x: am5.percent(50),
      //   centerX: am5.percent(50),
      //   paddingTop: 0,
      //   paddingBottom: 0
      // }));

      series.columns.template.setAll({
        width: 20,
        height: 70,
        tooltipY: 0,
        strokeOpacity: 1,
        strokeWidth:2,
        stroke:am5.color(0xffffff),
        templateField: "columnSettings"
      });

      series.bullets.push((root, target, dataItem) => {
        if (dataItem.dataContext.currentBullet) {
          if (this.selectedCountry.value_str !== '') {
            let container = am5.Container.new(root, {
              rotation: 90,
              dx: -20
            });

            let pin = container.children.push(am5.Graphics.new(root, {
              fill: dataItem.dataContext.columnSettings.fill,
              dy: -5,
              dx: 35,
              rotation: 180,
              centerY: am5.p100,
              centerX: am5.p50,
              svgPath: "M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z"
            }));
            let textColor = am5.color("#ffffff");
            if (dataItem.dataContext.category == 5 || dataItem.dataContext.category == 4) {
              textColor = am5.color("#000000");
            }
            let label = container.children.push(am5.Label.new(root, {
              text: dataItem.dataContext.risk,
              rotation: -90,
              dy: 27,
              dx: 35,
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
              paddingTop: 6,
              paddingRight: 6,
              paddingBottom: 6,
              paddingLeft: 6,
              fill: textColor,
              background: am5.RoundedRectangle.new(root, {
                fill: dataItem.dataContext.columnSettings.fill,
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBR: 20,
                cornerRadiusBL: 20,
              })
            }));

            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: container
            });
          }
        } else if (dataItem.dataContext.targetBullet) {
          let container = am5.Container.new(root, {
            dx: 15
          });
          return am5.Bullet.new(root, {
            locationY: 0.5,
            sprite: container
          });
        }
        return false;
      });

      series.data.setAll(data);

      chart.children.unshift(am5.Label.new(root, {
        text: "Low Risk",
        fontSize: 15,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(5),
        y: am5.percent(101),
        paddingTop: 0,
        paddingBottom: 0
      }));

      // Add label at the top
      chart.children.unshift(am5.Label.new(root, {
        text: "Very High Risk",
        fontSize: 15,
        fontWeight: "500",
        x: am5.percent(-30),
        y: am5.percent(2),
        paddingTop: 0,
        paddingBottom: 0
      }));

      // chart.children.unshift(am5.Label.new(root, {
      //   text: "Global Reputation Risk Assessment",
      //   fontSize: 25,
      //   fontWeight: "500",
      //   textAlign: "center",
      //   x: am5.percent(50),
      //   centerX: am5.percent(50),
      //   paddingTop: 0,
      //   paddingBottom: 0
      // }));

    series.appear(1000, 100);
    chart.appear(1000, 100);
    }
  },
  mounted() {
    this.GetCountry()
    this.renderChart()
  }
}
</script>