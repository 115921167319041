<template>
  <div class="chart-container3 w-100 rounded-2 m-1 overflow-hidden" id="chartdiv"></div>
</template>

<script>
/* eslint-disable */
import * as am5 from "@amcharts/amcharts5";
import * as am4core from "@amcharts/amcharts4/core";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import { scaleLinear } from "d3-scale";

export default {
  data() {
    return {
      data: [
      {
          category: "Environmental Risk",
          id: "pillar4risk",
          value: Math.round(this.data_country.pillar4risk * 100) / 100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(3)"
          }
        },
        {
          category: "Social & Societal Risk",
          id: "pillar3risk",
          value: Math.round(this.data_country.pillar3risk * 100) / 100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(2)"
          }
        },
        {
          category: "Political and Geopolitical Risk",
          id: "pillar1risk",
          value: Math.round(this.data_country.pillar1risk * 100) / 100,
          full: 5,
          columnSettings: {
            fill: "chart.get('colors').getIndex(1)"
          }
        }        
      ],
      selectedCountry: null
    };
  },
  props: {
    data_country: {
      type: Object,
      required: true,
    }
  },
  methods: {
    getColor(value) {
            const ranges = [1, 2.001, 3, 3.5, 4, 5];
            const colors = ['#029602', '#78be21', '#f1c232', '#eb4b52', '#cc0000'];

            let category = '';
            if (value >= 5) {
                category = colors[4];
            } else {
                for (let i = 1; i < ranges.length; i++) {
                    if (value >= ranges[i - 1] && value < ranges[i]) {
                        category = colors[i - 1];
                        break;
                    }
                }
            }
            return category
        },
    GetCountry() {
      this.countryIn = this.$route.params.country;
    },
    createChart() {
      var root = am5.Root.new("chartdiv");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      var chart = root.container.children.push(am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        innerRadius: am5.percent(20),
        startAngle: -90,
        endAngle: 180,
        layout: root.verticalLayout,
        background: am5.Rectangle.new(root, {
          fill: am5.color("#ffffff"),
          fillOpacity: 1,
        }),
      }));

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
      var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
        behavior: "zoomX"
      }));

      cursor.lineY.set("visible", false);

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        //minGridDistance: 50
      });

      xRenderer.labels.template.setAll({
        radius: 10
      });

      xRenderer.grid.template.setAll({
        forceHidden: true
      });

      var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 1,
        max: 5,
        strictMinMax: true,
        numberFormat: "#",
        // tooltip: am5.Tooltip.new(root, {})
      }));


      var yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 20
      });

      yRenderer.labels.template.setAll({
        centerX: am5.p100,
        fontWeight: "500",
        fontSize: 18,
        templateField: "columnSettings"
      });

      yRenderer.grid.template.setAll({
        forceHidden: true
      });

      var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer
      }));

      yAxis.data.setAll(this.data);


      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      var series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "full",
        categoryYField: "category",
        fill: root.interfaceColors.get("alternativeBackground")
      }));

      series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 20
      });

      series1.data.setAll(this.data);


      var series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "value",
        categoryYField: "category"
      }));

      series2.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        tooltipText: "{valueX}",
        cornerRadius: 20,
        templateField: "columnSettings"
      });

      series2.data.setAll(this.data);

      series2.columns.template.adapters.add("fill", (fill, target) => {
                if (this.getColor(target.dataItem.get("valueX"))) {
                    return am5.color(this.getColor(target.dataItem.get("valueX")))
                } else {
                    return am5.color("#000")
                }
      });

      series2.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series2.columns.indexOf(target ));
      });

      // chart.children.unshift(am5.Label.new(root, {
      //           text: "Reputation Risk Assessment by Pillar",
      //           fontSize: 25,
      //           fontWeight: "500",
      //           textAlign: "center",
      //           x: am5.percent(50),
      //           centerX: am5.percent(50),
      //           paddingTop: 0,
      //           paddingBottom: 0
      //       }));
      // Animate chart and series in
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      
      series1.appear(1000);
      series2.appear(1000);
      chart.appear(1000, 100);
    }
  },
  mounted() {
    this.GetCountry();
    this.createChart();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
