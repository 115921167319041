<template>
  <div>
    <br>
    <div class="grayed-background mx-4 border border-0">
      <table>
      <tr>
        <td class="label fw-bold p-1">Name : <span class="fw-lighter">{{ infoname }}</span></td>
      </tr>
      <tr>
        <td class="label fw-bold p-1">Description : <span class="fw-lighter">{{ infodesc }}</span></td>
      </tr>
      <tr>
        <td class="label fw-bold p-1">Unit : <span class="fw-lighter">{{ infounit }}</span></td>
      </tr>
      <tr>
        <td class="label fw-bold p-1">Source :
          <span class="fw-lighter">
            <a v-if="infosource && isHttp" :href="infosource" target="_blank">{{ infosource }}</a>
            <span v-else>{{ infosource }}</span>
          </span>
        </td>
      </tr>
      <!-- <tr>
        <td class="label fw-bold p-1">Frequency of update by the source: <span class="fw-lighter">{{ infofreq }}</span></td>
      </tr> -->
      <tr>
        <td class="label fw-bold p-1">Last value date : <span class="fw-lighter">{{ infolastd }}</span></td>
      </tr>
    </table>
  </div>
  </div>
  </template>
  
  <script>
  /* eslint-disable */
  import api from '@/services/api'
  import moment from 'moment'

  export default {
    components: {
      Map
    },
    data() {
      return {
        whichTab: false,
        countries: [],
        SelectedInd: '',
        infoname: '',
        infodesc: '',
        infofreq: '',
        infounit: ''
      }
    },
    methods: {
      async getIndicatorInfo() {
          try {
            const reponse = await api.get(`ssind/${this.selectedInd}`)
            const info = await reponse.data.data
            this.infoname = info[0].name;
            this.infodesc = info[0].description;
            if (info[0].frequency === 'A') {
                this.infofreq = "Annual";
            } else if (info[0].frequency === 'Q') {
                this.infofreq = "Quarterly";
            } else {
                this.infofreq = "Annual";
            }
            /*this.infounit = info[0].unit;*/
            this.infounit = "From 1 (low risk) to 5 (very high risk)"
            this.infosource = info[0].source;
            this.infolastd = moment(info[0].last_date).format('YYYY-MM-DD');
          } catch (error) {
            console.error('Erreur lors de la récupération des données', error)
          }
        },
      selectedIndicator()  {
        if (this.$route.params.element) {
          const indicator = this.$route.params.element.split('?')
          this.selectedInd = indicator[0];
        }
      }
    },
    computed: {
      isHttp() {
        return this.infosource && this.infosource.startsWith('http');
      }
    },
    async mounted() {
        this.selectedIndicator();
        await this.getIndicatorInfo()
    }
  }
  </script>
