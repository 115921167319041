<template>
    <div class="container d-flex justify-content-start gap-5 flex-wrap col mx-2 ">
    <MenuDropDown
        :data='data'
        :title='title'
        :queryTitle='queryTitle'
        :path='path'
        :titleV="'true'"
        :push="true"
        :margin="true"
        @IndicatorName="getIndicatorName"
    />
    <div class="search-bar-container d-flex justify-content-center align-items-center col-12 col-lg-5">
        <ul class="menu3 mb-0 w-100">
            <li class="searchBox">
            <input
                v-model="searchTerm"
                placeholder="Search for an indicator"
                @click.stop
                @keyup.enter="performSearch"
                class="border border-0"
            />
            </li>
            <li 
            v-for="(item, index) in filteredind"
            :key="index"
            :class="{ active: selectedIndex === index }"
            @click="new_selected(item)"
            > {{ item[0] }}  </li>
        </ul>
    </div>
    </div>
    <div>
        <IndicatorInfo/>
    </div>
    <div class="Indicator-tab m-4">
        <div class="tabs d-flex col gap-3 flex-wrap flex-md-nowrap">
        <div class="tabs__sidebar col-12 col-md-2">
        <button class="tabs__button d-block p-1 border border-0 w-100" :class="{ 'tabs__button--active w-100 d-block ': whichTab }" @click="changeTab(true)">World Map</button>
        <button class="tabs__button d-block p-1 border border-0 w-100 outline-none" :class="{ 'tabs__button--active ': !whichTab }" @click="changeTab(false)">Country Ranking</button>
      </div>
        <div class="tabs__content col-12 col-md-10" :class="{ 'tabs__content--active d-block ': !whichTab }">
            <IndicatorTab :PdfTitle='PdfTitle'/>
        </div>
        </div>
        <div v-if="Object.keys(map).length > 0" class="tabs__content" :class="{ 'tabs__content--active  w-100 d-block': whichTab }">
            <Map :map="map"/>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import MenuDropDown from '../components/UI/MenuDropDownIndicator'
import IndicatorTab from '../components/Indicator/IndicatorTab.vue'
import IndicatorName from './app.json'
import api from '@/services/api'
import Map from '../components/Indicator/IndicatorMap.vue'
import IndicatorInfo from '../components/Indicator/IndicatorInfo.vue'
import { countryToAlpha2 } from "country-to-iso";

export default {
    components: {
        MenuDropDown,
        IndicatorTab,
        Map,
        IndicatorInfo
    },
    data() {
        return {
            title: 'Select an Indicator',
            queryTitle: 'Search Indicator',
            indicator: [
            ],
            path: 'indicator',
            selectedC: 'Indicator',
            data: IndicatorName,
            map : {},
            whichTab: true,
            selectedInd: '',
            PdfTitle: '',
            searchTerm: '',
            inds: [],
            filteredind: this.inds,
            selectedIndex: -1,
        }
    },
    methods: {
        getIndicatorName(data) {
             this.PdfTitle = data
        },
        async getIndicatorInfo() {
          try {
            const reponse = await api.get(`ssind/${this.selectedInd}`)
            const info = await reponse.data.data
            this.infoname = info[0].name;
            this.infodesc = info[0].description;
            this.infofreq = info[0].descriptifrequencyon;
            this.infounit = info[0].unit;
          } catch (error) {
            console.error('Erreur lors de la récupération des données', error)
          }
        },
        async getIndicator() {
          try {
            const reponse = await api.get(`data/ssind/${this.selectedInd}?start_date=2024-01-01&end_date=2024-12-31`)
            const data = await reponse.data
            data.data.forEach(obj => {
                    const Objname = obj.country_id;
                    this.map[Objname] = {
                        name: countryToAlpha2(obj.country_id),
                        risk: obj.myvalue,
                    };
                });
          } catch (error) {
            console.error('Erreur lors de la récupération des données', error)
          }
        },
        selectedIndicator()  {
        if (this.$route.params.element) {
          const indicator = this.$route.params.element.split('?')
          this.selectedInd = indicator[0];
            }
        },
        changeTab(targetValue) {
        if (targetValue !== this.whichTab) {
          this.whichTab = targetValue;
        }
      },
      getDataFromJson() {
            for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                    const item = this.data[key];
                    const all_code = item.code.split('/')
                    const raw_code = all_code[all_code.length - 1];
                    const itemArray = [item.description, 'indicator', raw_code];
                    this.inds.push(itemArray);
                }
            }
        },
        filterinds() {
          if (this.searchTerm.trim() === '') {
            this.filteredind = [];
          } else {
            this.filteredind = this.inds.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
      new_selected(array) {
        this.$router.push(`/indicator/${array[2]}`).then(() => {
            window.location.reload();
        });
        },
        performSearch() {
            const searchTermLower = this.searchTerm.toLowerCase();
            const foundItem = this.filteredind.find(item => item[0].toLowerCase() === searchTermLower);
            if (foundItem) {
                this.new_selected(foundItem);
            }
        },
        handleKeyPress(event) {
            if (event.key === 'ArrowUp') {
                this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
            } else if (event.key === 'ArrowDown') {
                if (Array.isArray(this.filteredind) && this.filteredind.length > 0) {
                this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredind.length - 1);
                }
            } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
                this.new_selected(this.filteredind[this.selectedIndex]);
            } else if (event.key === 'Escape') {
                this.filteredind = [];
                this.selectedIndex = -1;
            }
        },
        handleClickOutside(event) {
            const menuContainer = document.querySelector('.search-bar-container');

            // Check if the menuContainer exists and the click is outside of it
            if (menuContainer && !menuContainer.contains(event.target)) {
                this.filteredind = [];
                this.selectedIndex = -1;
            }
        },
    },
    watch: {
      searchTerm() {
        this.filterinds();
      },
    },
    created() {
    // Add event listener for clicks on the document body
        document.body.addEventListener('click', this.handleClickOutside);
    },
    mounted() {
        this.getDataFromJson();
        this.selectedIndicator();
        this.getIndicatorInfo();
        this.getIndicator();
        document.addEventListener('keydown', this.handleKeyPress);
        
    },
    destroyed() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.body.removeEventListener('click', this.handleClickOutside);
    },
}
</script>