import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    showDisclaimer: true,
    jsonData: {},
    isLoggedIn: false
  },
  mutations: {
    acceptDisclaimer(state) {
      state.showDisclaimer = false;
    },
    setJsonData(state, data) {
      state.jsonData = data;
    },
    setIsLoggedIn(state, data) {
      state.isLoggedIn = data;
    }
  },
  actions: {
  },
  getters: {
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    }
  },
});
